import React, {useState} from "react";
import {Link} from "react-router-dom";
import {SRLWrapper} from "simple-react-lightbox";
import ModalVideo from "react-modal-video";

function PackageDetailsTab({data}) {
    const {
        id,
        details,
        pickup,
        departure,
        return_,
        included,
        excluded,
        travel_by,
        other_images,
        itinerary,
        gallery,
        map_track,

    } = data;
    const {vehicle, icon} = travel_by
    const [activeTab, setActiveTab] = useState('information');
    const [itineraryListOpen, setItineraryListOpen] = useState(0)

    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    };
    const handleTabsChange = (data) => {
        setActiveTab(data)
    }

    const handleListOpen = (data) => {
        if (itineraryListOpen === data)
            return setItineraryListOpen(-1)
        setItineraryListOpen(data)
    }


    const Information = () => {
        return (<div
                className="tab-pane fade show active package-info-tab mt-5">
                {/*<h3 className="d-subtitle">Package Details</h3>*/}
                <p style={{textAlign: 'justify'}}>
                    {details && details}
                </p>
                {
                    other_images &&
                    <div className="p-info-featured-img row position-relative g-3  row-cols-1 row-cols-sm-2">
                        <div className="col">
                            <div className="featured-img">
                                <img
                                    style={{borderRadius: '5px',}}
                                    src={
                                        process.env.PUBLIC_URL + other_images[0]
                                    }
                                    alt="PackageIMG"
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="featured-img">
                                <img
                                    style={{borderRadius: '5px'}}
                                    src={
                                        process.env.PUBLIC_URL + other_images[1]
                                    }
                                    alt="PackageIMG"
                                />
                            </div>
                        </div>
                    </div>
                }

                <table className="table package-info-table mb-0">
                    <tbody>
                    <tr>
                        <th>Pickup</th>
                        <td>{pickup && pickup}</td>
                    </tr>
                    <tr>
                        <th>Depature</th>
                        <td>{departure && departure}</td>
                    </tr>
                    {/* <tr>
                                <th>Departure Time</th>
                                <td>01 April, 2021 10.00AM</td>
                            </tr>*/}
                    <tr>
                        <th>Return</th>
                        <td>{return_ && return_} </td>
                    </tr>
                    <tr>
                        <th>Included</th>
                        <td>
                            <ul className="included-list">
                                {included && included.map((item, index) => {
                                    return (<li key={index}>
                                        <i className="bi bi-check2"/>
                                        {item}
                                    </li>)
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Excluded</th>
                        <td>
                            <ul className="excluded-list">
                                {excluded && excluded.map((item, index) => {
                                    return (<li key={index}>
                                        <i className="bi bi-x-lg"/>
                                        {item}
                                    </li>)
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="tour-transport-col">
                            <div className="tour-transport  d-flex align-items-center justify-content-center">
                                <img
                                    src={process.env.PUBLIC_URL + icon}
                                    alt="PackageIMG"
                                />
                                <span>{vehicle}</span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className="rating-overview">
                    <h3 className="d-subtitle">Overview</h3>
                    <div className="rating-overview-row row g-0">
                        <div className="col-lg-4 col-md-5 col-sm-5">
                            <div
                                className="total-rating d-flex justify-content-center align-items-center flex-column h-100 ">
                                <h3>10.00</h3>
                                <h5>Excellent</h5>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 col-sm-7">
                            <div className="rating-info">
                                <div className="rating-box">
                                    <h6>
                                        Accommodation <span>5.0</span>
                                    </h6>
                                    <div className="rating-bar"/>
                                </div>
                                <div className="rating-box">
                                    <h6>
                                        Transport <span>5.0</span>
                                    </h6>
                                    <div className="rating-bar"/>
                                </div>
                                <div className="rating-box">
                                    <h6>
                                        Comfort <span>5.0</span>
                                    </h6>
                                    <div className="rating-bar"/>
                                </div>
                                <div className="rating-box">
                                    <h6>
                                        Hospitality <span>5.0</span>
                                    </h6>
                                    <div className="rating-bar"/>
                                </div>
                                <div className="rating-box">
                                    <h6>
                                        Food <span>5.0</span>
                                    </h6>
                                    <div className="rating-bar"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    const Itinerary = () => {
        return (
            <div className="tab-pane fade show active package-plan-tab tab-body">
                <div className="accordion plans-accordion" id="planAccordion">
                    {itinerary && itinerary.map((item, index) => {
                        const {arrow, title, description} = item;
                        return <div key={index} className="accordion-item plans-accordion-single">
                            <div className="accordion-header" id={`planHeading${arrow}`}
                                 onClick={() => handleListOpen(index)}>
                                <div
                                    className="accordion-button">
                                    <div className="paln-index-circle">
                                        <h4>{index + 1}</h4>
                                    </div>
                                    <div className="plan-title">
                                        <h5>DAY {index + 1} : {title}</h5>
                                        {/*<h6>10.00 AM to 10.00 PM</h6>*/}
                                    </div>
                                </div>
                            </div>
                            <div id={`planCollapse${index + 1}`}
                                 className={`accordion-collapse collapse ${index === itineraryListOpen ? 'show' : ''}`}>

                                <div className="accordion-body plan-info">
                                    <ul>
                                        {description && description.map((it, indx) => {
                                            return <li>
                                                <i className="bi bi-check-lg"/> {it}
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>

                    })}
                </div>
            </div>)
    }

    const Gallery = () => {
        return (
            <div className="tab-pane fade show active package-gallary-tab">
                <SRLWrapper>
                    <div className="row g-4">
                        {gallery && gallery.map((g_item, g_index) => {
                            console.log("Gallery Items ", g_item)
                            if (g_item.length === 2) {
                                return (
                                    <>
                                        {

                                            g_item[0]['2'] && <div className="col-6">
                                                <div className="package-gallary-item">
                                                    <a
                                                        href={
                                                            process.env.PUBLIC_URL + g_item[0]['1']
                                                        }
                                                        data-fancybox="gallery"
                                                        data-caption="Caption Here"
                                                    >
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL + g_item[0]['2']
                                                            }
                                                            alt="PackageIMG"
                                                        />
                                                    </a>
                                                </div>
                                            </div>


                                        }
                                        {
                                            g_item[1]['2'] && <div className="col-6">
                                                <div className="package-gallary-item">
                                                    <a
                                                        href={
                                                            process.env.PUBLIC_URL + g_item[1]['1']
                                                        }
                                                        data-fancybox="gallery"
                                                        data-caption="Caption Here"
                                                    >
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL + g_item[1]['2']
                                                            }
                                                            alt="PackageIMG"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        }

                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        <div className="col-12">
                                            <div className="package-gallary-item">
                                                <a
                                                    href={
                                                        process.env.PUBLIC_URL + g_item[0]['1']
                                                    }
                                                    data-fancybox="gallery"
                                                    data-caption="Caption Here"
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL + g_item[0]['2']
                                                        }
                                                        alt="PackageIMG"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        })}
                    </div>
                </SRLWrapper>
            </div>
        )
    }

    const MapTrack = () => {
        return (
            <div className="tab-pane fade show active package-location-tab">
                <div className="mapouter">
                    <div className="gmap_canvas">
                        {console.log("Map track: ", map_track)}
                        <iframe
                            title="fs"
                            id="gmap_canvas"

                            src={map_track && map_track}
                        />

                        <br/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="package-details-tabs">
                <ul className="nav nav-pills tab-switchers gap-xxl-4 gap-3">
                    <li className="nav-item" role="presentation">
                        <button
                            type="button"
                            onClick={() => handleTabsChange('information')}
                            className={`nav-link ${activeTab == 'information' ? 'active' : ''}`}
                        >
                            <i className="bi bi-info-lg"/> Information
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            type="button"
                            onClick={() => handleTabsChange('itinerary')}
                            className={`nav-link ${activeTab == 'itinerary' ? 'active' : ''}`}
                        >
                            <i className="bi bi-file-earmark-spreadsheet"/>
                            Itinerary
                        </button>
                    </li>
                    {
                        <Link
                            onClick={scrollTop}
                            to={{
                                pathname: `${process.env.PUBLIC_URL}/tour-images/${id}`,
                            }}
                        >
                            <li className="nav-item" role="presentation">
                                <button
                                    type="button"
                                    onClick={() => handleTabsChange('gallery')}
                                    className={`nav-link ${activeTab == 'gallery' ? 'active' : ''}`}
                                >
                                    <i className="bi bi-images"/> Tour Gallary
                                </button>
                            </li>
                        </Link>
                    }
                    {
                        map_track && <li className="nav-item" role="presentation">
                            <button
                                type="button"
                                onClick={() => handleTabsChange('map_track')}
                                className={`nav-link ${activeTab == 'map_track' ? 'active' : ''}`}
                            >
                                <i className="bi bi-geo-alt"/> Location
                            </button>
                        </li>
                    }


                </ul>
                <div className="tab-content" id="pills-tabContent">
                    {
                        activeTab === 'information' ?
                            <Information/>
                            : activeTab === 'itinerary' ?
                                <Itinerary/>
                                : activeTab === 'gallery' ?
                                    <Gallery/>
                                    : <MapTrack/>
                    }

                </div>
            </div>

        </>
    );
}

export default PackageDetailsTab;
