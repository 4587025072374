import React, {useState, useReducer, useEffect} from "react";
import {Link, NavLink} from "react-router-dom";
import Select from "react-select";
import TopBar from '../common/TopBar'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

function Header({message}) {
    const [isSearchActive, setSearchActive] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [isCatagoryActive, setCatagoryActive] = useState(false);
    const [isOpenActive, setOpenActive] = useState(false);

    /*useing reducer to manage the active or inactive menu*/


    useEffect(() => {
        // Add scroll event when the component is loaded
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('header');
        const scrollTop = window.scrollY;
        scrollTop >= 200 ? header.classList.add('sticky') : header.classList.remove('sticky');
    };


    const initialState = {activeMenu: ""};
    const [state, dispatch] = useReducer(reducer, initialState);

    function reducer(state, action) {
        switch (action.type) {
            case "homeOne":
                return {activeMenu: "homeOne"};
            case "page":
                return {activeMenu: "page"};
            case "package":
                return {activeMenu: "package"};
            case "blog":
                return {activeMenu: "blog"};
            case "destination":
                return {activeMenu: "destination"};
            default:
                return {activeMenu: " "};
        }
    }

    const handleSearcghBtn = () => {
        if (isSearchActive === false || isSearchActive === 0) {
            setSearchActive(1);
        } else {
            setSearchActive(false);
        }
    };

    const handleCatagorybtn = () => {
        if (isCatagoryActive === false || isCatagoryActive === 0) {
            setCatagoryActive(1);
        } else {
            setCatagoryActive(false);
        }
    };
    const handleOpenBtn = () => {
        if (isOpenActive === false || isOpenActive === 0) {
            setOpenActive(1);
        } else {
            setOpenActive(false);
        }
    };

    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    };
    const optionsForDestination = [
        {value: "1", label: "India"},
        {value: "2", label: "Sri Lanka"},
        {value: "3", label: "Solt Lake"},
        {value: "4", label: " Kolkata"},
        {value: "6", label: " Mohania"},
        {value: "7", label: " Mumbai"},
        {value: "8", label: " New Delhi"},
    ];
    const optionsForTravel = [
        {value: "1", label: "Adventure Tour"},
        {value: "2", label: "Group Tour"},
        {value: "3", label: "Couple Tour"},
        {value: "4", label: "Sea Beach"},
        {value: "5", label: "Mountain Tour"},
    ];
    const optionsForPerson = [
        {value: "1", label: "01"},
        {value: "2", label: "02"},
        {value: "3", label: "03"},
        {value: "4", label: "04"},
        {value: "5", label: "05"},
    ];
    return (
        <>
            <div
                className={`${"main-searchbar-wrapper"} ${
                    isSearchActive === 1 ? "search-active" : ""
                }`}
            >
                <div className="container">
                    <div className="multi-main-searchber">
                        <div
                            onClick={handleSearcghBtn}
                            className="main-searchbar-close search-toggle"
                        >
                            <i className="bi bi-x-lg"/>
                        </div>
                        <form onSubmit={(e) => e.preventDefault()} id="main_searchbar">
                            <div className="row g-4">
                                <div className="col-lg-10">
                                    <div className="row gx-0 gy-4">
                                        <div className="col-lg-3 col-md-6">
                                            <div className="select-box">
                                                <div className="searchbox-icons">
                                                    <i className="bi bi-geo-alt"/>
                                                </div>
                                                <div className="searchbox-input">
                                                    <label htmlFor="activity-dropdown">Destination</label>
                                                    <Select
                                                        placeholder="Whare are you going?"
                                                        className="selectbox"
                                                        onChange={setSelectedOption}
                                                        defaultValue={selectedOption}
                                                        options={optionsForDestination}
                                                        touchUi={false}
                                                        showGroupWheel={true}
                                                    ></Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="select-box">
                                                <div className="searchbox-icons">
                                                    <i className="bi bi-text-paragraph"/>
                                                </div>
                                                <div className="searchbox-input">
                                                    <label htmlFor="activity-dropdown">Travel Type</label>
                                                    <Select
                                                        className="selectbox"
                                                        placeholder="All activities"
                                                        defaultValue={selectedOption}
                                                        onChange={setSelectedOption}
                                                        options={optionsForTravel}
                                                    ></Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="select-box">
                                                <div className="searchbox-icons">
                                                    <i className="bi bi-person-plus"/>
                                                </div>
                                                <div className="searchbox-input">
                                                    <label htmlFor="activity-dropdown">Person</label>
                                                    <Select
                                                        className="selectbox"
                                                        onChange={setSelectedOption}
                                                        placeholder="Person"
                                                        defaultValue={selectedOption}
                                                        options={optionsForPerson}
                                                    ></Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="select-box">
                                                <div className="searchbox-icons">
                                                    <i className="bi bi-capslock"/>
                                                </div>
                                                <div className="searchbox-input">
                                                    <label htmlFor="activity-dropdown">
                                                        Journey Date
                                                    </label>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(date) => setStartDate(date)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="main-form-submit">
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/package`}
                                            onClick={scrollTop}
                                        >
                                            <button type="submit">Find Now</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div
                className={`${"category-sidebar-wrapper"} ${
                    isCatagoryActive === 1 ? "category-active" : ""
                }`}
            >
                <div className="category-sidebar">
                    <div className="category-header d-flex justify-content-between align-items-center">
                        <h4>Category</h4>
                        <div onClick={handleCatagorybtn} className="category-toggle">
                            <i className="bi bi-x-lg"/>
                        </div>
                    </div>
                    <div className="row row-cols-lg-3 row-cols-2 gy-5 mt-3">
                        <div className="col">
                            <Link
                                className="category-box"
                                onClick={scrollTop}
                                to={`${process.env.PUBLIC_URL}/package`}
                            >
                                <div className="cate-icon mx-auto">
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/icons/cate1.svg"}
                                        alt="catagoryIMG"
                                    />
                                </div>
                                <h5>Adventure</h5>
                            </Link>
                        </div>
                        <div className="col">
                            <Link
                                className="category-box"
                                onClick={scrollTop}
                                to={`${process.env.PUBLIC_URL}/package`}
                            >
                                <div className="cate-icon mx-auto">
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/icons/cate2.svg"}
                                        alt="catagoryIMG"
                                    />
                                </div>
                                <h5>Group Tour</h5>
                            </Link>
                        </div>
                        <div className="col">
                            <Link
                                className="category-box"
                                onClick={scrollTop}
                                to={`${process.env.PUBLIC_URL}/package`}
                            >
                                <div className="cate-icon mx-auto">
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/icons/cate3.svg"}
                                        alt="catagoryIMG"
                                    />
                                </div>
                                <h5>Couple Tour</h5>
                            </Link>
                        </div>
                        <div className="col">
                            <Link
                                className="category-box"
                                onClick={scrollTop}
                                to={`${process.env.PUBLIC_URL}/package`}
                            >
                                <div className="cate-icon mx-auto">
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/icons/cate4.svg"}
                                        alt="catagoryIMG"
                                    />
                                </div>
                                <h5>Single Tour</h5>
                            </Link>
                        </div>
                        <div className="col">
                            <Link
                                className="category-box"
                                onClick={scrollTop}
                                to={`${process.env.PUBLIC_URL}/package`}
                            >
                                <div className="cate-icon mx-auto">
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/icons/cate5.svg"}
                                        alt="catagoryIMG"
                                    />
                                </div>
                                <h5>Honeymoon</h5>
                            </Link>
                        </div>
                        <div className="col">
                            <Link
                                className="category-box"
                                onClick={scrollTop}
                                to={`${process.env.PUBLIC_URL}/package`}
                            >
                                <div className="cate-icon mx-auto">
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/icons/cate6.svg"}
                                        alt="catagoryIMG"
                                    />
                                </div>
                                <h5>Sea Beach</h5>
                            </Link>
                        </div>
                        <div className="col">
                            <Link
                                className="category-box"
                                onClick={scrollTop}
                                to={`${process.env.PUBLIC_URL}/package`}
                            >
                                <div className="cate-icon mx-auto">
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/icons/cate7.svg"}
                                        alt="catagoryIMG"
                                    />
                                </div>
                                <h5>Mountain Tour</h5>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {<TopBar message={message}/>}

            <header>
                <div className="header-area header-style-one">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 align-items-center d-xl-flex d-lg-block">
                                <div className="logo d-flex justify-content-between align-items-center">
                                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                                        <img
                                            width={80}
                                            src={process.env.PUBLIC_URL + "/images/logo.png"}
                                            alt="logo"
                                        />
                                    </Link>
                                    <div className="d-flex align-items-center gap-4">
                                        <div className="nav-right d-xl-none">
                                            <ul className="nav-actions">
                                            </ul>
                                        </div>
                                        <div
                                            className="mobile-menu d-flex "
                                            onClick={handleOpenBtn}
                                        >
                                            <Link
                                                to={"#"}
                                                className={
                                                    isOpenActive
                                                        ? "hamburger d-block d-xl-none h-active"
                                                        : "hamburger d-block d-xl-none"
                                                }
                                            >
                                                <span className="h-top"/>
                                                <span className="h-middle"/>
                                                <span className="h-bottom"/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-6">
                                <nav
                                    className={
                                        isOpenActive
                                            ? "main-nav float-end slidenav"
                                            : "main-nav float-end"
                                    }
                                >
                                    <div className="logo d-xl-none text-center">
                                        <Link to={"#"}>
                                            <img
                                                width={80}
                                                src={process.env.PUBLIC_URL + "/images/logo.png"}
                                                alt="catagoryIMG"
                                            />
                                        </Link>
                                    </div>
                                    <ul>
                                        <li onClick={() => dispatch({type: 'homeOne'})}>
                                            <Link to={`${process.env.PUBLIC_URL}/tours`}>Home</Link>
                                        </li>
                                        <li>
                                            <NavLink
                                                onClick={scrollTop}
                                                to={`${process.env.PUBLIC_URL}/about`}
                                            >
                                                About Us
                                            </NavLink>
                                        </li>
                                        <li className="has-child-menu" onClick={() => dispatch({type: 'Tours'})}>
                                            <Link to={"#"}>Tours</Link>
                                            <i className="fl flaticon-plus">+</i>
                                            <ul className={state.activeMenu === "Tours" ? "sub-menu d-block" : "sub-menu d-none"}>
                                                <li>
                                                    <NavLink
                                                        onClick={scrollTop}
                                                        to={`${process.env.PUBLIC_URL}/tours/all`}
                                                    >
                                                        All
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        onClick={scrollTop}
                                                        to={`${process.env.PUBLIC_URL}/tours/adventure`}
                                                    >
                                                        Adventure
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        onClick={scrollTop}
                                                        to={`${process.env.PUBLIC_URL}/tours/cultural`}
                                                    >
                                                        Cultural
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        onClick={scrollTop}
                                                        to={`${process.env.PUBLIC_URL}/tours/sightseen`}
                                                    >
                                                        SightSeen
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <NavLink
                                                onClick={scrollTop}
                                                to={`${process.env.PUBLIC_URL}/destinations`}
                                            >
                                                Destinations
                                            </NavLink>
                                        </li>
                                        {/*<li onClick={() => dispatch({type: 'tour_card'})}>
                                            <Link to={`${process.env.PUBLIC_URL}/tours`}>Tours</Link>

                                        </li>
                                        <li className="has-child-menu" onClick={() => dispatch({type: 'destination'})}>
                                            <Link to={"#"}>Destination</Link>
                                            <i className="fl flaticon-plus">+</i>
                                            <ul className={state.activeMenu === "destination" ? "sub-menu d-block" : "sub-menu d-none"}>
                                                <li>
                                                    <NavLink
                                                        onClick={scrollTop}
                                                        to={`${process.env.PUBLIC_URL}/destination`}
                                                    >
                                                        Destination
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        onClick={scrollTop}
                                                        to={`${process.env.PUBLIC_URL}/destination-details`}
                                                    >
                                                        Destination Details
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </li>*/}

                                        <li>
                                            <NavLink
                                                onClick={scrollTop}
                                                to={`${process.env.PUBLIC_URL}/contact`}
                                            >
                                                Contact Us
                                            </NavLink>
                                        </li>
                                    </ul>
                                    <div className="inner-contact-options d-xl-none">
                                        <div className="contact-box-inner">
                                            <i className="bi bi-telephone-fill"/>
                                            <Link to="tel:+92-51-5567178">+92-51-5567178</Link>
                                        </div>
                                        <div className="contact-box-inner">
                                            <i className="bi bi-envelope-fill"/>
                                            <Link to="mailto:ali@zoongroup.com">ali@zoongroup.com</Link>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <div className="col-xl-2">
                                <div className="nav-right float-end d-xl-flex d-none ">
                                    <ul className="nav-actions">
                                        {/*<li className="category-toggle" onClick={handleCatagorybtn}>
                      <i className="bx bx-category" />
                    </li>*/}
                                        {/*<li className="search-toggle" onClick={handleSearcghBtn}>
                      <i className="bx bx-search-alt" />
                    </li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* ===============  header area end =============== */}
        </>
    );
}

export default Header;
