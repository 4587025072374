import React from "react";


function HHeader({data}) {
    const {title, sub_title, duration_d_n, tour_type, group_size, tour_guide, cover_image} = data;

    return (
        <>
            <div className="pd-header">
                <div className="header-bottom">
                    <div className="pd-lavel d-flex justify-content-between align-items-center flex-wrap gap-2">
                        <h5 className="location">
                            <i className="bi bi-geo-alt"/> {sub_title && sub_title}
                        </h5>
                    </div>
                    <h2 className="pd-title">{title}</h2>
                </div>
                <div className="pd-thumb">
                    <img src={process.env.PUBLIC_URL + cover_image} alt="PackageIMG"/>
                </div>
            </div>
        </>
    );
}

export default HHeader;
