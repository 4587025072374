import React, {useRef, useState} from "react";
import {Link} from "react-router-dom";
import emailjs from "@emailjs/browser";
import {Backdrop, CircularProgress} from "@mui/material";

function ContactWrapperArea() {
    const [submittingDataLoader, setSubmittingDataLoader] = useState(false)
    const YOUR_SERVICE_ID = 'service_mwt0g1p';
    const YOUR_TEMPLATE_ID = 'template_ojvlqjj';
    const YOUR_PUBLIC_KEY = 'OLrPv-_2c4o-kDsjT';
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setSubmittingDataLoader(true)
        emailjs.sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, form.current, YOUR_PUBLIC_KEY)
            .then((result) => {
                console.log(result.text);
                setSubmittingDataLoader(false)
                e.target.reset();
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <>
            <div className="contact-wrapper pt-110">
                <div className="container">
                    <div className="row align-items-center justify-content-lg-center gy-5">
                        <div className="col-lg-6">
                            <div className="contatc-intro-figure" style={{marginRight:'80px'}}>
                                <img
                                    src={process.env.PUBLIC_URL + "/images/logo.png"}
                                    alt="ContactBannerIMG"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="contact-info" >
                                <h3>Contact Info.</h3>
                                <ul>
                                    <li style={{display:'flex', flexDirection:'column', rowGap:'10px'}}>
                                        <h6>Let’s Talk</h6>
                                        <a href="mailto:ali@zoongroup.com">Email: ali@zoongroup.com</a>
                                        {/*<a href="mailto:ali@zoongroup.com">info@support.com</a>*/}
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href="tel:+92-51-5567178"
                                        >
                                           Office: +92-51-5567178
                                        </a>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href="tel:+92-51-5581310"
                                        >
                                            Office: +92-51-5581310
                                        </a>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href="tel:+92-321-85422224"
                                        >
                                            Mobile: +92-321-85422224
                                        </a>
                                    </li>
                                    <li>
                                        <h6>Location.</h6>
                                        <a href="https://goo.gl/maps/8cLmgUVZ322s9FLA6">
                                            Zoon Safari 23-B Haider Road Rawalpindi
                                        </a>
                                    </li>
                                    <li>
                                        <h6>Visit Us.</h6>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href="https://www.facebook.com/egenslab"
                                        >
                                            Facebook: ____
                                        </a>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href="https://twitter.com/EgensLab"
                                        >
                                            Twitter: ____
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0">
                    <div className="contact-map mt-120">
                        <div className="mapouter">
                            <div className="gmap_canvas">
                                <iframe
                                    title="map"
                                    id="gmap_canvas"
                                    src={
                                        "https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3323.446086783045!2d73.05088341525126!3d33.593728380733104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38df949d3a1b97a9%3A0xd30be9e05555fb94!2sHUNTING%20IN%20PAKISTAN%20(Zoon)%2C%2023b%20Haider%20Rd%2C%20Saddar%2C%20Rawalpindi%2C%20Punjab%2046000%2C%20Pakistan!3m2!1d33.5937474!2d73.0530933!5e0!3m2!1sen!2s!4v1664050748087!5m2!1sen!2s"
                                    }
                                />
                                <Link to={"https://123movies-to.org"}/>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pt-110">
                    <Backdrop
                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                        open={submittingDataLoader}
                        onClick={() => setSubmittingDataLoader(false)}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <form ref={form} onSubmit={sendEmail} id="contact_form">
                        <div className="contact-form-wrap">
                            <h4>Get a free key stroke quote now</h4>
                            <p>
                                Your email address will not be published. Required fields are
                                marked *
                            </p>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="custom-input-group">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            required
                                            id="name"
                                            name="name"
                                            type="text"
                                            placeholder="Your name"

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-input-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            required
                                            id="email"
                                            name="email"
                                            type="email"
                                            placeholder="Your Email"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="custom-input-group">
                <textarea
                    cols={20}
                    rows={7}
                    required
                    name="message"
                    placeholder="Your message"
                    defaultValue={""}
                />
                            </div>
                            <div className="custom-input-group">
                                <div className="submite-btn">
                                    <button type="submit">Send Message</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ContactWrapperArea;
