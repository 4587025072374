import React from "react";
import {Link} from "react-router-dom";
import PackageCard from "../packageGrid/PackageCard";
import tours from '../../../database/tours';

function PackageArea() {
    const scrolltop = () => window.scrollTo({top: 0, behavior: "smooth"});
    return (
        <>
            <div className="package-area package-style-one pt-110 ">
                <div className="container">
                    <div className="row  align-items-center">
                        <div className="col-lg-8">
                            <div className="section-head-alpha">
                                <h2>Popular Tour Package</h2>
                                <p>
                                    The mountains of Baltistan are the most noteworthy on the
                                    planet. Climbing among them is an encounter, while
                                    investigating the valleys and the nearby culture….
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="package-btn text-lg-end">
                                <Link
                                    to={`${process.env.PUBLIC_URL}/tours`}
                                    onClick={scrolltop}
                                    className="button-fill-primary"
                                >
                                    View All Tour
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row g-4">
                        {tours && tours.map((p_item, p_index) => {
                            const {image, date, title, price, to, gap, des, availability, active, status} = p_item
                            if (active && status === 'popular') {
                                return <div className="col-lg-4 col-md-6">
                                    <PackageCard
                                        image={image}
                                        date={date}
                                        title={title}
                                        price={price}
                                        to={to}
                                        des={des}
                                        gap={gap}
                                        availability={availability}
                                    />
                                </div>
                            }
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default PackageArea;
