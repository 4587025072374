const images = [
    [
        [
            {
                1: '/images/gallary/skardu/back-skardu-1.jpg', 2: '/images/gallary/skardu/front-skardu-1.png'
            },

            {
                1: '/images/gallary/skardu/back-skardu-2.jpg', 2: '/images/gallary/skardu/front-skardu-2.png'
            }
        ],
        [
            {
                1: '/images/gallary/skardu/back-skardu-3.jpg', 2: '/images/gallary/skardu/front-skardu-3.png'
            },
        ],
        [
            {
                1: '/images/gallary/skardu/back-skardu-4.jpeg', 2: '/images/gallary/skardu/front-skardu-4.png'
            },

            {
                1: '/images/gallary/skardu/back-skardu-5.jpeg', 2: '/images/gallary/skardu/front-skardu-5.png'
            }
        ],
        [
            {
                1: '/images/gallary/skardu/back-skardu-6.JPG', 2: '/images/gallary/skardu/front-skardu-6.png'
            },
        ],
        [
            {
                1: '/images/gallary/skardu/back-skardu-7.JPG', 2: '/images/gallary/skardu/front-skardu-7.png'
            },

            {
                1: '/images/gallary/skardu/back-skardu-8.JPG', 2: '/images/gallary/skardu/front-skardu-8.png'
            }
        ],
        [
            {
                1: '/images/gallary/skardu/back-skardu-9.JPG', 2: '/images/gallary/skardu/front-skardu-9.png'
            },
            {},
        ],
    ],
    [],
    [
        [
            {
                1: '/images/buddhist/back-buddhist-1.jpg', 2: '/images/buddhist/front-buddhist-1.png'
            },

            {
                1: '/images/buddhist/back-buddhist-2.jpg', 2: '/images/buddhist/front-buddhist-2.png'
            }
        ],
        [
            {
                1: '/images/buddhist/back-buddhist-5.jpg', 2: '/images/buddhist/front-buddhist-5.png'
            },
        ],
        [
            {
                1: '/images/buddhist/back-buddhist-3.jpg', 2: '/images/buddhist/front-buddhist-3.png'
            },

            {
                1: '/images/buddhist/back-buddhist-4.jpg', 2: '/images/buddhist/front-buddhist-4.png'
            }
        ],
        [
            {
                1: '/images/buddhist/back-buddhist-6.jpg', 2: '/images/buddhist/front-buddhist-6.png'
            },
        ],
        [
            {
                1: '/images/buddhist/back-buddhist-7.jpg', 2: '/images/buddhist/front-buddhist-7.png'
            },

            {
                1: '/images/buddhist/back-buddhist-9.jpg', 2: '/images/buddhist/front-buddhist-9.png'
            }
        ],
        [
            {
                1: '/images/buddhist/back-buddhist-8.jpg', 2: '/images/buddhist/front-buddhist-8.png'
            },
        ],
        [
            {
                1: '/images/buddhist/back-buddhist-10.jpg', 2: '/images/buddhist/front-buddhist-10.png'
            },

            {
                1: '/images/buddhist/back-buddhist-11.jpg', 2: '/images/buddhist/front-buddhist-11.png'
            }
        ],
        [
            {
                1: '/images/buddhist/back-buddhist-12.jpg', 2: '/images/buddhist/front-buddhist-12.png'
            },

            {}
        ],
    ],
    [],
    [],
    [
        [
            {
                1: '/images/hunza/back-hunza-1.jpg', 2: '/images/hunza/front-hunza-1.png'
            },

            {
                1: '/images/hunza/back-hunza-2.jpg', 2: '/images/hunza/front-hunza-2.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-3.jpg', 2: '/images/hunza/front-hunza-3.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-4.jpg', 2: '/images/hunza/front-hunza-4.png'
            },

            {
                1: '/images/hunza/back-hunza-5.jpg', 2: '/images/hunza/front-hunza-5.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-6.jpg', 2: '/images/hunza/front-hunza-6.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-7.jpg', 2: '/images/hunza/front-hunza-7.png'
            },

            {
                1: '/images/hunza/back-hunza-8.jpg', 2: '/images/hunza/front-hunza-8.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-9.jpg', 2: '/images/hunza/front-hunza-9.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-10.jpg', 2: '/images/hunza/front-hunza-10.png'
            },

            {
                1: '/images/hunza/back-hunza-11.jpg', 2: '/images/hunza/front-hunza-11.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-12.jpg', 2: '/images/hunza/front-hunza-12.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-13.jpg', 2: '/images/hunza/front-hunza-13.png'
            },

            {
                1: '/images/hunza/back-hunza-14.jpg', 2: '/images/hunza/front-hunza-14.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-15.jpg', 2: '/images/hunza/front-hunza-15.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-16.jpg', 2: '/images/hunza/front-hunza-16.png'
            },

            {
                1: '/images/hunza/back-hunza-17.jpg', 2: '/images/hunza/front-hunza-17.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-18.jpg', 2: '/images/hunza/front-hunza-18.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-19.jpg', 2: '/images/hunza/front-hunza-19.png'
            },

            {
                1: '/images/hunza/back-hunza-20.jpg', 2: '/images/hunza/front-hunza-20.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-21.jpg', 2: '/images/hunza/front-hunza-21.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-22.jpg', 2: '/images/hunza/front-hunza-22.png'
            },

            {
                1: '/images/hunza/back-hunza-23.jpg', 2: '/images/hunza/front-hunza-23.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-24.jpg', 2: '/images/hunza/front-hunza-24.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-25.jpg', 2: '/images/hunza/front-hunza-25.png'
            },

            {
                1: '/images/hunza/back-hunza-26.jpg', 2: '/images/hunza/front-hunza-26.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-27.jpg', 2: '/images/hunza/front-hunza-27.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-28.jpg', 2: '/images/hunza/front-hunza-28.png'
            },

            {
                1: '/images/hunza/back-hunza-29.jpg', 2: '/images/hunza/front-hunza-29.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-30.jpg', 2: '/images/hunza/front-hunza-30.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-31.jpg', 2: '/images/hunza/front-hunza-31.png'
            },

            {
                1: '/images/hunza/back-hunza-32.jpg', 2: '/images/hunza/front-hunza-32.png'
            },
        ],
    ],
    [],
    [
        [
            {
                1: '/images/hunza/back-hunza-1.jpg', 2: '/images/hunza/front-hunza-1.png'
            },

            {
                1: '/images/hunza/back-hunza-2.jpg', 2: '/images/hunza/front-hunza-2.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-3.jpg', 2: '/images/hunza/front-hunza-3.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-4.jpg', 2: '/images/hunza/front-hunza-4.png'
            },

            {
                1: '/images/hunza/back-hunza-5.jpg', 2: '/images/hunza/front-hunza-5.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-6.jpg', 2: '/images/hunza/front-hunza-6.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-7.jpg', 2: '/images/hunza/front-hunza-7.png'
            },

            {
                1: '/images/hunza/back-hunza-8.jpg', 2: '/images/hunza/front-hunza-8.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-9.jpg', 2: '/images/hunza/front-hunza-9.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-10.jpg', 2: '/images/hunza/front-hunza-10.png'
            },

            {
                1: '/images/hunza/back-hunza-11.jpg', 2: '/images/hunza/front-hunza-11.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-12.jpg', 2: '/images/hunza/front-hunza-12.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-13.jpg', 2: '/images/hunza/front-hunza-13.png'
            },

            {
                1: '/images/hunza/back-hunza-14.jpg', 2: '/images/hunza/front-hunza-14.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-15.jpg', 2: '/images/hunza/front-hunza-15.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-16.jpg', 2: '/images/hunza/front-hunza-16.png'
            },

            {
                1: '/images/hunza/back-hunza-17.jpg', 2: '/images/hunza/front-hunza-17.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-18.jpg', 2: '/images/hunza/front-hunza-18.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-19.jpg', 2: '/images/hunza/front-hunza-19.png'
            },

            {
                1: '/images/hunza/back-hunza-20.jpg', 2: '/images/hunza/front-hunza-20.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-21.jpg', 2: '/images/hunza/front-hunza-21.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-22.jpg', 2: '/images/hunza/front-hunza-22.png'
            },

            {
                1: '/images/hunza/back-hunza-23.jpg', 2: '/images/hunza/front-hunza-23.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-24.jpg', 2: '/images/hunza/front-hunza-24.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-25.jpg', 2: '/images/hunza/front-hunza-25.png'
            },

            {
                1: '/images/hunza/back-hunza-26.jpg', 2: '/images/hunza/front-hunza-26.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-27.jpg', 2: '/images/hunza/front-hunza-27.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-28.jpg', 2: '/images/hunza/front-hunza-28.png'
            },

            {
                1: '/images/hunza/back-hunza-29.jpg', 2: '/images/hunza/front-hunza-29.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-30.jpg', 2: '/images/hunza/front-hunza-30.png'
            },
        ],
        [
            {
                1: '/images/hunza/back-hunza-31.jpg', 2: '/images/hunza/front-hunza-31.png'
            },

            {
                1: '/images/hunza/back-hunza-32.jpg', 2: '/images/hunza/front-hunza-32.png'
            },
        ],
    ],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [
        [
            {
                1: '/images/balutistan/balutistan1.jpg', 2: '/images/baluhistan/balutistan-1.png'
            },

            {
                1: '/images/baluhistan/balutistan2.jpg', 2: '/images/baluhistan/balutistan-2.png'
            },
        ],
        [
            {
                1: '/images/balutistan/balutistan3.jpg', 2: '/images/baluhistan/balutistan-3.png'
            },

            {
                1: '/images/baluhistan/balutistan4.JPG', 2: '/images/baluhistan/balutistan-4.png'
            },
        ],
        [
            {
                1: '/images/balutistan/balutistan5.JPG', 2: '/images/baluhistan/balutistan-5.png'
            },

            {
                1: '/images/baluhistan/balutistan6.JPG', 2: '/images/baluhistan/balutistan-6.png'
            },
        ],
        [
            {
                1: '/images/balutistan/balutistan7.JPG', 2: '/images/baluhistan/balutistan-7.png'
            },

            {
                1: '/images/baluhistan/balutistan8.JPG', 2: '/images/baluhistan/balutistan-8.png'
            },
        ],
        [
            {
                1: '/images/balutistan/balutistan9.JPG', 2: '/images/baluhistan/balutistan-9.png'
            },

            {
                1: '/images/baluhistan/balutistan10.JPG', 2: '/images/baluhistan/balutistan-10.png'
            },
        ],
        [
            {
                1: '/images/balutistan/balutistan11.JPG', 2: '/images/baluhistan/balutistan-11.png'
            },

            {
                1: '/images/baluhistan/balutistan12.JPG', 2: '/images/baluhistan/balutistan-12.png'
            },
        ],
        [
            {
                1: '/images/mohenjodaro/mohenjodaro-1.jpg', 2: '/images/mohenjodaro/mohenjodaro-1.png'
            },

            {
                1: '/images/mohenjodaro/mohenjodaro-2.jpg', 2: '/images/mohenjodaro/mohenjodaro-2.png'
            },
        ],
        [
            {
                1: '/images/mohenjodaro/mohenjodaro-5.jpg', 2: '/images/mohenjodaro/mohenjodaro-5.png'
            },

            {
                1: '/images/mohenjodaro/mohenjodaro-4.jpg', 2: '/images/mohenjodaro/mohenjodaro-4.png'
            },
        ],
    ],
    [
        [
            {
                1: '/images/lahore/badshahi-mosque-lahore.jpg', 2: '/images/lahore/badshahi-mosque-lahore.png'
            },

            {
                1: '/images/lahore/truck-art.jpg', 2: '/images/lahore/truck-art.png'
            },
        ],
        [
            {
                1: '/images/lahore/wagha-broder-2.jpg', 2: '/images/lahore/wagha-broder-2.png'
            },

            {
                1: '/images/lahore/wagha-broder-flag-ceremony.jpg', 2: '/images/lahore/wagha-broder-flag-ceremony.png'
            },
        ],
    ],
]


export default images
