import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import PackageGridWrapper from "./PackageGridWrapper";
import tours from '../../../database/tours';
import {useParams} from "react-router-dom";

function PackageGrid() {
    const {cat} = useParams()
    let toursData;
    if (cat === 'adventure' || cat === 'cultural' || cat === 'sightseen') {
        toursData = tours.filter(item => item.category === cat);
    } else
        toursData = tours;
    return (
        <>
            <Breadcrumb name="Tour Packages"/>
            <PackageGridWrapper tours={toursData}/>
        </>
    );
}

export default PackageGrid;
