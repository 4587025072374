import React from "react";
import HPackageCard from "../packageGrid/HPackageCard";
import huntings from "../../../database/huntings";

function HPackageArea() {
  return (
    <>
      <div className="package-area package-style-one pt-60 ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="section-head-alpha">
                <h2>ZOON SAFARI PAKISTAN</h2>
                <h5>
                  GOVERNMENT OF PAKISTAN REGISTRATION NUMBER : ID-1053 EU-
                  TRACES APPROVAL NUMBER 04-AQD-ZOON
                </h5>
                <p>
                  We are one of the top registered professional Pakistan
                  outfitters. We organize hunting trips all over the Pakistan
                  since 2006. ZOON Safari has Hunting Trekking and Sightseeing
                  Department ready to serve your individual needs. Whether you
                  would like to enjoy your hunting holidays or Trekking and
                  Sightseeing tours– we are ready to welcome you. from snow
                  capped mountains in north to the deserted mountains in south
                  .We offer hunting of Kashmir Markhor , Astor Markhor ,
                  Sulieman Markhor Punjab Urial , Blandford Urial Afghan Urial ,
                  Himalayan Blue Sheep , Himalayan Ibex, Sindh Ibex And Wild
                  Boars in Pakistan.
                </p>
                <p>
                  We operate in the best hunting areas and closely work with
                  Federal Government and provisional wildlife departments and
                  local communities. Our Team consists of professional wildlife
                  biologists and booking managers , Professional Guides and
                  Cooks and Drivers who do their best to provide you with
                  unforgettable hunting experience . We will meet you upon
                  arrival at the airport, help with all the custom formalities
                  and make sure your trophy hunt goes smoothly and brings great
                  results. Our services include visa invitations, gun permits
                  and necessary international veterinary certificates. We are
                  one the five companies which from Pakistan which are
                  Registered with EU-TRACES
                </p>
                <p>
                  Our hunting camps vary from the comfortable base lodges and
                  traditional Asian houses to the spike camps used to hunt
                  particular animal or area. Depending on the specifics of the
                  hunting destination, we can arrange helicopter, jeep or
                  horseback transportation to the camp if required.
                </p>
                <p>We are Proud Member and partners of</p>
              </div>
            </div>
            {/*<div className="col-lg-4">*/}
            {/*    <div className="package-btn text-lg-end">*/}
            {/*        <Link*/}
            {/*            to={`${process.env.PUBLIC_URL}/tours`}*/}
            {/*            onClick={scrolltop}*/}
            {/*            className="button-fill-primary"*/}
            {/*        >*/}
            {/*            View All Tour*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>
          <div className="row g-4">
            {huntings &&
              huntings.map((p_item, p_index) => {
                const {
                  image,
                  date,
                  title,
                  price,
                  to,
                  gap,
                  des,
                  availability,
                  active,
                  status,
                } = p_item;
                if (active && status === "popular") {
                  return (
                    <div className="col-lg-4 col-md-6">
                      <HPackageCard
                        image={image}
                        date={date}
                        title={title}
                        price={price}
                        to={to}
                        des={des}
                        gap={gap}
                        availability={availability}
                      />
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default HPackageArea;
