import React from "react";
import {useParams} from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import HPackageDetailsWrap from "./HPackageDetailsWrap";
import hunting from '../../../database/huntings_detail'
import Error from '../error/Error'

function HPackageDetails() {
    const {id} = useParams()
    console.log("tour_card details: ", hunting[id - 1])
    const data = hunting[id - 1];
    return (
        <>
            {
                data ?
                    <>
                        <Breadcrumb name="Package Details"/>
                        <HPackageDetailsWrap data={{...data, id}} />
                    </>
                    : <>
                        <Error/>
                    </>
            }
        </>
    )
}

export default HPackageDetails
