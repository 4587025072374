import React from "react";
import { Link } from "react-router-dom";
// import HWidgetForm from "./HWidgetForm";
// import {HContactUs} from "./HContactUs";
// import HWidgetPackageOffer from "./HWidgetPackageOffer";
// import HWidgetPackageTag from "./HWidgetPackageTag";
import TourHeader from "./TourHeader";
import TourImagesTab from "./TourImagesTab";

function TourImagesWrap({data}) {
  console.log("Data in Wrapper: ", data)

  return (
    <>
      <div className="package-details-wrapper pt-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tour-package-details">
                <TourHeader data={data}/>
                <TourImagesTab id={data.id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TourImagesWrap;
