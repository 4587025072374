const destinations_detail = [
    {
        title: 'Hunza Valley',
        place: '--',
        hotel: '--',
        p:['','','',''],
        images: ['/images/destination/p_destination_hunza_valley.png','',''],
        map: ''
    }, {
        title: 'Chitral',
        place: '--',
        hotel: '--',
        p:['','','',''],
        images: ['/images/destination/p_destination_chitral.png','',''],
        map: ''
    }, {
        title: 'Baltistan',
        place: '--',
        hotel: '--',
        p:['','','',''],
        images: ['/images/destination/p_destination_balkistan.png','',''],
        map: ''
    }, {
        title: 'Azad Kashmir',
        place: '--',
        hotel: '--',
        p:['','','',''],
        images: ['/images/destination/p_destination_azad_kashmir.png','',''],
        map: ''
    }, {
        title: 'Swat Valley',
        place: '--',
        hotel: '--',
        p:['','','',''],
        images: ['/images/destination/p_destination_swat.png','',''],
        map: ''
    }, {
        title: 'Rush Lake',
        place: '--',
        hotel: '--',
        p:['','','',''],
        images: ['/images/destination/p_destination_rush_lake.png','',''],
        map: ''
    }, {
        title: 'Lahore',
        place: '--',
        hotel: '--',
        p:['','','',''],
        images: ['/images/destination/p_destination_lahore.png','',''],
        map: ''
    }, {
        title: 'K2',
        place: '--',
        hotel: '--',
        p:['','','',''],
        images: ['/images/destination/p_destination_k2.png','',''],
        map: ''
    },

]

export default destinations_detail