const destinations = [
    {
        id: 8,
        title: 'Hunza Valley',
        place: '--',
        hotel: '--',
        image: '/images/destination/p_destination_hunza_valley.png',
    }, {
        id: 0,
        title: 'Chitral',
        place: '--',
        hotel: '--',
        image: '/images/destination/p_destination_chitral.png',
    }, {
        id: 1,
        title: 'Skardu - Baltistan',
        place: '--',
        hotel: '--',
        image: '/images/destination/p_destination_balkistan.png',
    }, {
        id: 0,
        title: 'Azad Kashmir',
        place: '--',
        hotel: '--',
        image: '/images/destination/p_destination_azad_kashmir.png',
    }, {
        id: 0,
        title: 'Swat - Kalam - Dir Valley',
        place: '--',
        hotel: '--',
        image: '/images/destination/p_destination_swat.png',
    }, {
        id: 0,
        title: 'Fairy Meadow',
        place: '--',
        hotel: '--',
        image: '/images/destination/p_destination_rush_lake.png',
    }, {
        id: 17,
        title: 'Lahore',
        place: '--',
        hotel: '--',
        image: '/images/destination/p_destination_lahore.png',
    }, {
        id: 3,
        title: 'Gandhara Trails',
        place: '--',
        hotel: '--',
        image: '/images/destination/p_destination_k2.png',
    },

]

export default destinations