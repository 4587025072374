import React from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import HImagesWrap from "./HImagesWrap";
import HFooter from "../../common/HFooter";
import HHeader from "../../common/HHeader";
import huntings from "../../../database/huntings_detail";
import Error from "../error/Error";

function TourImages() {
  const { id } = useParams();
  console.log("huntings: ", huntings);
  console.log("huntings details: ", huntings[id - 1]);
  const data = huntings[id - 1];
  return (
    <>
      {data ? (
        <>
          <HHeader />
          <Breadcrumb name="Hunting Images" />
          <HImagesWrap data={{ ...data, id }} />
          <HFooter />
        </>
      ) : (
        <>
          <Error />
        </>
      )}
    </>
  );
}

export default TourImages;
