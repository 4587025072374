import React from "react";
import {useParams} from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import DestinationDetailsWrapper from "./DestinationDetailsWrapper";
import destinations_detail from '../../../database/destinations_detail'

function DestinationDetails() {
        const {id} = useParams()
    const data = destinations_detail[id - 1]
    return (
        <>
            <Breadcrumb name="Destination Details"/>
            <DestinationDetailsWrapper data={data}/>
        </>
    )
}

export default DestinationDetails