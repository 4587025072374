import React from "react";
import HWidgetForm from "./HWidgetForm";
import HPackageHeader from "./HPackageHeader";
import HPackageDetailsTab from "./HPackageDetailsTab";

function HPackageDetailsWrap({data}) {
  console.log("Data in Wrapper: ", data)

  return (
    <>
      <div className="package-details-wrapper pt-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="tour-package-details">
                <HPackageHeader data={data}/>
                <HPackageDetailsTab data={data} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="package-sidebar">
                <HWidgetForm data={data}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HPackageDetailsWrap;
