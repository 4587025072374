import { createContext, useEffect, useState } from "react";



const InitialState = {
    user: JSON.parse(localStorage.getItem('user')) ?? null
}
export const UserContext = createContext(InitialState)
export const UserContextProvider = ({children}) =>{
const [userDetails, setuserDetails] = useState({});

useEffect(() => {
    localStorage.setItem('user',JSON.stringify(userDetails))
}, [userDetails]);
return (
    <UserContext.Provider value={ {userDetails,setuserDetails}}>
        {children}
    </UserContext.Provider>
)
}
