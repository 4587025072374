import React, {useState} from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutWrapperArea() {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <div className="about-main-wrappper pt-110">
                <div className="container">
                    <div className="about-tab-wrapper">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="about-tab-image-grid text-center">
                                    <div className="about-video d-inline-block">
                                        <img src={process.env.PUBLIC_URL + "/images/about/about_g2.png"}
                                             alt="aboutIMG"/>
                                        <div className="video-overlay">
                                            <div className="play-icon video-popup">
                                                <i
                                                    onClick={() => setOpen(true)}
                                                    className="bi bi-play-fill"
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="row float-images g-4">
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="about-image">
                                                <img src={process.env.PUBLIC_URL + "/images/about/about-g1.png"}
                                                     alt="aboutIMG"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="about-image">
                                                <img src={process.env.PUBLIC_URL + "/images/about/about-g3.png"}
                                                     alt="aboutIMG"/>
                                            </div>
                                        </div>
                                    </div>*/}
                                </div>
                            </div>
                            <div className="col-lg-6 mt-5 mt-lg-0">
                                <div className="about-tab-wrap">
                                    <h2 className="about-wrap-title">
                                        About Our Company And <span>What We Are</span> Offer.
                                    </h2>
                                    <div className="about-tab-switcher">
                                        <ul
                                            className="nav nav-pills mb-3 justify-content-md-between justify-content-center"
                                            id="about-tab-pills"
                                            role="tablist"
                                        >
                                            <li className="nav-item" role="presentation">
                                                <div
                                                    className="nav-link active"
                                                    id="pills-about1"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#about-pills1"
                                                    role="tab"
                                                    aria-controls="about-pills1"
                                                    aria-selected="true"
                                                >
                                                    <h3>16+</h3>
                                                    <h6>Years Experience</h6>
                                                </div>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <div
                                                    className="nav-link"
                                                    id="pills-about2"
                                                    // data-bs-toggle="pill"
                                                    // data-bs-target="#about-pills2"
                                                    // role="tab"
                                                    // aria-controls="about-pills2"
                                                    // aria-selected="false"
                                                >
                                                    <h3>30+</h3>
                                                    <h6>Tour Guide</h6>
                                                </div>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <div
                                                    className="nav-link"
                                                    id="pills-about3"
                                                    // data-bs-toggle="pill"
                                                    // data-bs-target="#about-pills3"
                                                    // role="tab"
                                                    // aria-controls="about-pills3"
                                                    // aria-selected="false"
                                                >
                                                    <h3>150+</h3>
                                                    <h6>Travelar Connect</h6>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        className="tab-content about-tab-content"
                                        id="pills-tabContent"
                                    >
                                        <div
                                            className="tab-pane fade show active"
                                            id="about-pills1"
                                            role="tabpanel"
                                            aria-labelledby="pills-about1"
                                        >
                                            <p style={{textAlign: 'justify'}}>
                                                Zoon Safaris enjoys an excellent reputation within the tourism industry
                                                in Pakistan. We are a member of: </p>
                                            <div className="about-us-points" style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                rowGap: '5px',
                                                fontWeight: '500',
                                                color: '#EEB61A'
                                            }}>
                                                <span>Pakistan Association of Tour Operators (PATO)</span>
                                                <span>Safari club international USA (SCI)</span>
                                                <span>Wild Sheep Foundation USA ((WSF)</span>
                                                <span>Ovis Club (GSCO)</span>
                                                <span>Gilgit Baltistan Association of Tour Operator (GBATO)</span>
                                                <span>Dallas Safari Club (DSC)</span>
                                            </div>

                                            <p style={{textAlign: 'justify'}}>
                                                Those governing bodies regulate and promote tourism and Eco- Tourism in
                                                Pakistan.
                                                Zoon Safari` founded in 2006 - is a registered tour Operator company
                                                ID-1053 with Government of Pakistan a Pakistan owned & owner managed
                                                company, currently employing a total of 25 staff. Concentrating on both,
                                                Corporate Travel Management, Community Based Trophy Hunting & Individual
                                                Travel Arrangements, we do have sufficient manpower to take care of any
                                                travel arrangements required.
                                                We remain committed to our principles of giving individual and
                                                personalized services to each customer.

                                                Our wealth of experience stands behind our firm commitment to duly offer
                                                the best and finest breathtaking Museums, Historical Sites (Indus and
                                                Gangadhara Civilizations,) mountain Trekking, Bike Tours, Trophy Hunting
                                                and Mountain climbing tours in Pakistan. We offer exceptional
                                                tailor-made itineraries, incentive travels, conference booking, family
                                                vacations, beach holidays, air and road packages and much more.
                                                The combination of our experienced team of travel consultants and our
                                                certified driver and guides assures a safe, treasurable, thrilling, and
                                                informative safari.
                                                Zoon Safaris is committed to offer all clients professional, personal,
                                                and cost-effective travel services
                                            </p>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="about-pills2"
                                            role="tabpanel"
                                            aria-labelledby="pills-about2"
                                        >
                                            <p>

                                            </p>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="about-pills3"
                                            role="tabpanel"
                                            aria-labelledby="pills-about3"
                                        >
                                            <p>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-110">
                        <div className="col-lg-6">
                            <div className="achievement-counter-wrap">
                                <h2 className="about-wrap-title">
                                    Why Tour Book With
                                    <span> Zoon Safari?</span>
                                </h2>
                                <div className="achievement-counter-cards">

                                    <div
                                        className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center ">
                                        <div className="counter-box mb-sm-0 mb-3">
                                            {/*<h2>500+</h2>*/}
                                            <h6>Corporate Travel Management</h6>
                                        </div>
                                        <p style={{textAlign: 'justify'}}>
                                            We have our IT online booking system we ensure, that our corporate business
                                            traveler receives travel related information from an innovative network of
                                            resources, keeping him connected whilst on the move.
                                            Certified travel consultants guarantee that our corporate clients receive
                                            the best airline market fares available.
                                        </p>
                                    </div>
                                    <div
                                        className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                                        <div className="counter-box">
                                            {/*<h2>300+</h2>*/}
                                            <h6>Safaris and Tours</h6>
                                        </div>
                                        <p style={{textAlign: 'justify'}}>
                                            We operate tours in all over Pakistan, National Museums, Historical Sites
                                            (Indus and Gangadhara Civilizations,) mountain Trekking, Bike Tours, Trophy
                                            Hunting and Mountain climbing tours for individuals or groups as per
                                            arranged and customized itineraries in Pakistan. We will avail information
                                            on availability and costs within the shortest possible time.
                                        </p>
                                    </div>
                                    <div
                                        className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                                        <div className="counter-box">
                                            {/*<h2>150+</h2>*/}
                                            <h6> Hotels and Lodge Reservations</h6>
                                        </div>
                                        <p style={{textAlign: 'justify'}}>
                                            We undertake Hotel and Lodge reservations in all major Pakistan cities on
                                            behalf of our customers at discounted rates.
                                        </p>
                                    </div>

                                    <div
                                        className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                                        <div className="counter-box">
                                            {/*<h2>150+</h2>*/}
                                            <h6> Transport and Car Hire</h6>
                                        </div>
                                        <p style={{textAlign: 'justify'}}>
                                            Zoon Safaris maintains a fleet of 15 vehicles; all vehicles are equipped
                                            with real time GPS tracking (fleet management system) and 30 motor bikes.
                                            (150-250 CC)
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="achievement-counter-wrap">
                                <h2 className="about-wrap-title">
                                    ----
                                    <span> Zoon Safari?</span>
                                </h2>
                                <div className="achievement-counter-cards">

                                    <div
                                        className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                                        <div className="counter-box">
                                            {/*<h2>150+</h2>*/}
                                            <h6>Air Ticketing</h6>
                                        </div>
                                        <p style={{textAlign: 'justify'}}>
                                            Our partner Company is a member of IATA and linked to the Amadeus ticketing
                                            system of reservations that gives our travel consultants direct access to
                                            airline information and data, destination visa and health information,
                                            enabling them to answer your requests accurately and immediately. Our travel
                                            consultants will find the most effective route with the cheapest airfare
                                            available on international and domestic tickets.
                                        </p>
                                    </div>

                                    <div
                                        className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                                        <div className="counter-box">
                                            {/*<h2>150+</h2>*/}
                                            <h6> Conference Facilities</h6>
                                        </div>
                                        <p>
                                            Our good working relationship with regional and international hotel chains
                                            enable us to negotiate on behalf of our client’s competitive room
                                            arrangements or conference packages. We provide transport services for the
                                            delegates and on-conference support services at a minimal charge.
                                        </p>
                                    </div>
                                    <div
                                        className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                                        <div className="counter-box">
                                            {/*<h2>150+</h2>*/}
                                            <h6> Conference Facilities</h6>
                                        </div>
                                        <p>
                                            Our good working relationship with regional and international hotel chains
                                            enable us to negotiate on behalf of our client’s competitive room
                                            arrangements or conference packages. We provide transport services for the
                                            delegates and on-conference support services at a minimal charge.
                                        </p>
                                    </div>

                                    <div
                                        className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                                        <div className="counter-box">
                                            {/*<h2>150+</h2>*/}
                                            <h6> Private Air Charter Reservation</h6>
                                        </div>
                                        <p style={{textAlign: 'justify'}}>
                                            Available on short-notice.
                                        </p>
                                    </div>


                                </div>
                            </div>
                        </div>
                        {/*<div className="col-lg-6">*/}
                        {/*    <div className="about-image-group mt-5 mt-lg-0">*/}
                        {/*        <img src={process.env.PUBLIC_URL + "/images/about/about-image-group2.png"}*/}
                        {/*             alt="aboutIMG" className="img-fluid"/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <React.Fragment>
                <ModalVideo
                    channel="youtube"
                    autoplay
                    isOpen={isOpen}
                    videoId="ljLV1s5f62A"
                    onClose={() => setOpen(false)}
                />
            </React.Fragment>
        </>
    );
}

export default AboutWrapperArea;
