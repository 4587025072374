import React from "react";
import {SRLWrapper} from "simple-react-lightbox";
import images from '../../../database/images'

function TourImagesTab({id}) {
    const gallery = images[id - 1]
    return (
        <div className="tab-pane fade show active package-gallary-tab">
            <SRLWrapper>
                <div className="row g-4">
                    {gallery && gallery.map((g_item, g_index) => {
                        console.log("Gallery Items ", g_item)
                        if (g_item.length === 2) {
                            return (
                                <>
                                    {

                                        g_item[0]['2'] && <div className="col-6">
                                            <div className="package-gallary-item">
                                                <a
                                                    href={
                                                        process.env.PUBLIC_URL + g_item[0]['1']
                                                    }
                                                    data-fancybox="gallery"
                                                    data-caption="Caption Here"
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL + g_item[0]['2']
                                                        }
                                                        alt="PackageIMG"
                                                    />
                                                </a>
                                            </div>
                                        </div>


                                    }
                                    {
                                        g_item[1]['2'] && <div className="col-6">
                                            <div className="package-gallary-item">
                                                <a
                                                    href={
                                                        process.env.PUBLIC_URL + g_item[1]['1']
                                                    }
                                                    data-fancybox="gallery"
                                                    data-caption="Caption Here"
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL + g_item[1]['2']
                                                        }
                                                        alt="PackageIMG"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    }

                                </>
                            )
                        } else {
                            return (
                                <>
                                    <div className="col-12">
                                        <div className="package-gallary-item">
                                            <a
                                                href={
                                                    process.env.PUBLIC_URL + g_item[0]['1']
                                                }
                                                data-fancybox="gallery"
                                                data-caption="Caption Here"
                                            >
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL + g_item[0]['2']
                                                    }
                                                    alt="PackageIMG"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    })}
                </div>
            </SRLWrapper>
        </div>
    );
}

export default TourImagesTab;
