import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";

function HPackageDetailsTab({ data }) {
  const {
    id,
    details,
    travel_by,
    other_images,
    itinerary,
    gallery,
    map_track,
  } = data;
  const { vehicle, icon } = travel_by;
  const [activeTab, setActiveTab] = useState("information");
  const [itineraryListOpen, setItineraryListOpen] = useState(0);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleTabsChange = (data) => {
    setActiveTab(data);
  };

  const handleListOpen = (data, description) => {
    if (itineraryListOpen === data) return setItineraryListOpen(-1);
    if (description.length !== 0) setItineraryListOpen(data);
  };

  const Information = () => {
    return (
      <div className="tab-pane fade show active package-info-tab mt-5">
        {details &&
          details.map((item, index) => {
            const { heading, description } = item;
            return (
              <>
                {heading && <h3 className="d-subtitle mt-4">{heading}</h3>}
                {description && (
                  <p style={{ textAlign: "justify" }}>{description}</p>
                )}
              </>
            );
          })}
        {other_images.length > 0 && (
          <div className="p-info-featured-img row position-relative g-3  row-cols-1 row-cols-sm-2">
            <div className="col">
              <div className="featured-img">
                <img
                  style={{ borderRadius: "5px" }}
                  src={process.env.PUBLIC_URL + other_images[0]}
                  alt="PackageIMG"
                />
              </div>
            </div>
            <div className="col">
              <div className="featured-img">
                <img
                  style={{ borderRadius: "5px" }}
                  src={process.env.PUBLIC_URL + other_images[1]}
                  alt="PackageIMG"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const Itinerary = () => {
    return (
      <div className="tab-pane fade show active package-plan-tab tab-body">
        <h2></h2>
        <div className="accordion plans-accordion" id="planAccordion">
          {itinerary &&
            itinerary.map((item, index) => {
              const { arrow, title, description } = item;
              return (
                <div
                  key={index}
                  className="accordion-item plans-accordion-single"
                >
                  <div
                    className="accordion-header"
                    id={`planHeading${arrow}`}
                    onClick={() => handleListOpen(index, description)}
                  >
                    <div className="accordion-button">
                      <div className="paln-index-circle">
                        <h4>{index + 1}</h4>
                      </div>
                      <div className="plan-title">
                        <h5>
                          DAY {index + 1} : {title}
                        </h5>
                        {/*<h6>10.00 AM to 10.00 PM</h6>*/}
                      </div>
                    </div>
                  </div>
                  <div
                    id={`planCollapse${index + 1}`}
                    className={`accordion-collapse collapse ${
                      index === itineraryListOpen ? "show" : ""
                    }`}
                  >
                    <div className="accordion-body plan-info">
                      <ul>
                        {description &&
                          description.map((it, indx) => {
                            return (
                              <li>
                                <i className="bi bi-check-lg" /> {it}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const Gallery = () => {
    return (
      <div className="tab-pane fade show active package-gallary-tab">
        <SRLWrapper>
          <div className="row g-4">
            {gallery &&
              gallery.map((g_item, g_index) => {
                console.log("Gallery Items ", g_item);
                if (g_item.length === 2) {
                  return (
                    <>
                      {g_item[0]["2"] && (
                        <div className="col-6">
                          <div className="package-gallary-item">
                            <a
                              href={process.env.PUBLIC_URL + g_item[0]["1"]}
                              data-fancybox="gallery"
                              data-caption="Caption Here"
                            >
                              <img
                                src={process.env.PUBLIC_URL + g_item[0]["2"]}
                                alt="PackageIMG"
                              />
                            </a>
                          </div>
                        </div>
                      )}
                      {g_item[1]["2"] && (
                        <div className="col-6">
                          <div className="package-gallary-item">
                            <a
                              href={process.env.PUBLIC_URL + g_item[1]["1"]}
                              data-fancybox="gallery"
                              data-caption="Caption Here"
                            >
                              <img
                                src={process.env.PUBLIC_URL + g_item[1]["2"]}
                                alt="PackageIMG"
                              />
                            </a>
                          </div>
                        </div>
                      )}
                    </>
                  );
                } else {
                  return (
                    <>
                      <div className="col-12">
                        <div className="package-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + g_item[0]["1"]}
                            data-fancybox="gallery"
                            data-caption="Caption Here"
                          >
                            <img
                              src={process.env.PUBLIC_URL + g_item[0]["2"]}
                              alt="PackageIMG"
                            />
                          </a>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
          </div>
        </SRLWrapper>
      </div>
    );
  };

  const MapTrack = () => {
    return (
      <div className="tab-pane fade show active package-location-tab">
        <div className="mapouter">
          <div className="gmap_canvas">
            {console.log("Map track: ", map_track)}
            <iframe title="fs" id="gmap_canvas" src={map_track && map_track} />

            <br />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="package-details-tabs">
        <ul className="nav nav-pills tab-switchers gap-xxl-4 gap-3">
          <li className="nav-item" role="presentation">
            <button
              type="button"
              onClick={() => handleTabsChange("information")}
              className={`nav-link ${
                activeTab == "information" ? "active" : ""
              }`}
            >
              <i className="bi bi-info-lg" /> Information
            </button>
          </li>
          {itinerary.length > 0 && (
            <li className="nav-item" role="presentation">
              <button
                type="button"
                onClick={() => handleTabsChange("itinerary")}
                className={`nav-link ${
                  activeTab == "itinerary" ? "active" : ""
                }`}
              >
                <i className="bi bi-file-earmark-spreadsheet" />
                Itinerary
              </button>
            </li>
          )}

          {gallery && (
            <Link
              onClick={scrollTop}
              to={{
                pathname: `${process.env.PUBLIC_URL}/hunting-images/${id}`,
              }}
            >
              <li className="nav-item" role="presentation">
                <button
                  type="button"
                  onClick={() => handleTabsChange("gallery")}
                  className={`nav-link ${
                    activeTab == "gallery" ? "active" : ""
                  }`}
                >
                  <i className="bi bi-images" />Tour Gallary
                </button>
              </li>
            </Link>
          )}
          {map_track && (
            <li className="nav-item" role="presentation">
              <button
                type="button"
                onClick={() => handleTabsChange("map_track")}
                className={`nav-link ${
                  activeTab == "map_track" ? "active" : ""
                }`}
              >
                <i className="bi bi-geo-alt" /> Location
              </button>
            </li>
          )}
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {activeTab === "information" ? (
            <Information />
          ) : activeTab === "itinerary" ? (
            <Itinerary />
          ) : activeTab === "gallery" ? (
            <Gallery />
          ) : (
            <MapTrack />
          )}
        </div>
      </div>
    </>
  );
}

export default HPackageDetailsTab;
