import React from "react";
import {Link} from "react-router-dom";
import TopBar from '../common/TopBar'

function HHeader({message}) {
    return (
        <>
            {<TopBar message={message}/>}

            <header>
                <div className="header-area header-style-one">
                    <div className="container">
                        <div className="row">
                                <div className="logo col-1">
                                    <Link to={`${process.env.PUBLIC_URL}/hunting`}>
                                        <img
                                            width={80}
                                            src={process.env.PUBLIC_URL + "/images/hunting.png"}
                                            alt="logo"
                                        />
                                    </Link>
                                </div>

                            <div className="col-11 d-flex align-items-center justify-content-center">
                                <h2 style={{fontWeight: 800}}>HUNTING IN PAKISTAN</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default HHeader;
