import React from "react";
import {useParams} from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import PackageDetailsWrap from "./PackageDetailsWrap";
import tours from '../../../database/tours_detail'
import Error from '../error/Error'

function PackageDetails() {
    const {id} = useParams()
    console.log("tour_card details: ", tours[id - 1])
    const data = tours[id - 1];
    return (
        <>
            {
                data ?
                    <>
                        <Breadcrumb name="Package Details"/>
                        <PackageDetailsWrap data={{...data, id}} />
                    </>
                    : <>
                        <Error/>
                    </>
            }
        </>
    )
}

export default PackageDetails
