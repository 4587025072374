import React from 'react';
import {Link} from "react-router-dom";

const TopBar = ({message}) => {
    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    };
    return (<div className="topbar-area topbar-style-one">
        <div className="container">
            <div className="row">
                <div className="col-xl-4 align-items-center d-xl-flex d-none">
                    <div className="topbar-contact-left">
                        <ul className="contact-list">
                            <li>
                                <i className="bi bi-telephone-fill"/>
                                <a href="tel:+92-321-8542224"> +92-321-8542224</a>
                            </li>
                            <li>
                                <i className="bi bi-envelope-fill"/>
                                <a href="mailto:ali@zoongroup.com">ali@zoongroup.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-6 text-xl-center text-md-start text-center">
                    <div className="topbar-ad">
                        <Link
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/`}
                        >
                            {message && message ? message : 'Back to Main'}
                        </Link>
                    </div>
                </div>
                <div
                    className="col-xl-3 col-lg-6 col-md-6 d-md-flex  d-none align-items-center justify-content-end">
                    <ul className="topbar-social-links">
                        <li>
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.facebook.com/"
                            >
                                <i className="bx bxl-facebook"/>
                            </a>
                        </li>
                        <li>
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.instagram.com/"
                            >
                                <i className="bx bxl-instagram-alt"/>
                            </a>
                        </li>
                        <li>
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://twitter.com/"
                            >
                                <i className="bx bxl-twitter"/>
                            </a>
                        </li>
                        <li>
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.whatsapp.com/"
                            >
                                <i className="bx bxl-whatsapp-square"/>
                            </a>
                        </li>
                        <li>
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.pinterest.com/"
                            >
                                <i className="bx bxl-pinterest"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>)
}

export default TopBar;