import React from "react";
import {useParams} from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import TourImagesWrap from "./TourImagesWrap";
import tours from '../../../database/tours_detail'
import Error from '../error/Error'

function TourImages() {
    const {id} = useParams()
    console.log("tours: ", tours)
    console.log("tour_card details: ", tours[id - 1])
    const data = tours[id - 1];
    return (
        <>
            {
                data ?
                    <>
                        <Breadcrumb name="Tour Images"/>
                        <TourImagesWrap data={{...data, id}} />
                    </>
                    : <>
                        <Error/>
                    </>
            }
        </>
    )
}

export default TourImages
