import React from "react";
import {NavLink} from "react-router-dom";

function MainPage() {
    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    };
    return (

        <div className='main-page-container'>

            <div className='main-box'>
                <NavLink
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/tours`}
                >
                    <div className='main-card'>
                        <img src='images/zoonsafaritours.png' alt="Zoon Safari Tours" />
                    </div>
                </NavLink>

                <NavLink
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/hunting`}
                >
                <div className='main-card'>
                    <img src='images/hunting.png' alt="Zoon Safari Tours" />
                </div>
                </NavLink>
            </div>
            <div className='main-box marginT'>
                {/*<div className='main-card'>*/}
                {/*    <img src='images/logo.png' alt="Zoon Safari Tours" width={300}/>*/}
                {/*    <Typography style={{fontSize:'35px', fontWeight: '700'}}>Fine Art</Typography>*/}
                {/*</div>*/}
                <div className='main-card'>
                    <img src='images/Power.jpg' alt="Zoon Safari Tours" width={300}/>
                </div>
            </div>
        </div>
    );
}

export default MainPage;
