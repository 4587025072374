import React from "react";

import Pagination from "../../common/Pagination";
import DestinationCart from "./DestinationCart";
import destinations from '../../../database/destinations'

function DestionationWrapper() {
    return (
        <>
            <div className="destination-wrapper pt-110">
                <div className="container">
                    <div className="row g-3">

                        {destinations && destinations.map((dest, index) => {
                            const {title, place, hotel, image, id} = dest
                            return <div className="col-lg-3 col-md-4 col-sm-6">
                                <DestinationCart
                                    image={image}
                                    title={title}
                                    place={place}
                                    hotel={hotel}
                                    id={id}
                                />
                            </div>
                        })}
                        {/*<Pagination/>*/}
                    </div>
                </div>
            </div>
        </>
    );
}

export default DestionationWrapper;
