import React, { useEffect, useState } from "react";
import HFooter from "../common/HFooter";
import HHeader from "../common/HHeader";
import Hunting from "../pages/Hunting/Hunting";
import Loading from "../common/Loading";
function Hunting1() {
  const [load, setLoad] = useState(false);
  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 3000);
  }, []);
  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <HHeader />
          <Hunting />
          <HFooter className="footer-area mt-110" />
        </>
      )}
    </>
  );
}

export default Hunting1;
