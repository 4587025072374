import React from "react";
import HPackageArea from "./HPackageArea";

function Hunting() {
    return (
        <>
            <HPackageArea/>
        </>
    );
}

export default Hunting;
