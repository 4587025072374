import {Link, useLocation} from "react-router-dom";

function HFooter(props) {

    const {pathname} = useLocation();
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <div className={props.className}>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-4 col-md-6 order-lg-1 order-3 ">
                                <div className="copyright-link text-lg-start text-center">
                                    <p>
                                        Copyright 2022 <span
                                        style={{fontWeight: '600', color: '#EEB61A'}}>ZoonGroup</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4  order-lg-2 order-1">
                                <div className="footer-logo text-center">
                                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/hunting`}>
                                        <img width={80} src={process.env.PUBLIC_URL + "/images/hunting.png"}
                                             alt="FooterIMG"/>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 order-lg-3 order-2">
                                <div className="policy-links">
                                    <ul className="policy-list justify-content-lg-end justify-content-center">
                                        <li>
                                            <Link to={"#"}>Terms &amp; Condition</Link>
                                        </li>
                                        <li>
                                            <Link to={"#"}>Privacy Policy</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HFooter;
