import React from "react";
import Pagination from "../../common/Pagination";
import PackageCard from "./PackageCard";
import ProcessingWrapper from "../processing/ProcessingWrapper";

function PackageGridWrapper({tours}) {
    console.log("Found Data in Object: ", tours)
    return (
        <>
            {/* ===============  Package gird area start =============== */}
            <div className="package-wrapper pt-110">
                <div className="container">
                    <div className="row g-4">
                        {
                            tours.length > 0 ?
                                tours.map((p_item, p_index) => {
                                    const {image, date, title, price, to, gap, des, availability, active,} = p_item
                                    if (active) {
                                        return <div className="col-lg-4 col-md-6">
                                            <PackageCard
                                                image={image}
                                                date={date}
                                                title={title}
                                                price={price}
                                                to={to}
                                                des={des}
                                                gap={gap}
                                                availability={availability}
                                            />
                                        </div>
                                    }
                                }) : <ProcessingWrapper/>
                        }
                    </div>
                    {/*<Pagination/>*/}
                </div>
            </div>
            {/* ===============  Package gird area end =============== */}
        </>
    );
}

export default PackageGridWrapper;


// {
//     packages && packages.map((p_item, p_index) => {
//         const {image, date, title, price, to, gap, des, availability} = p_item
//         return <div className="col-lg-4 col-md-6">
//             <MainCard
//                 image={image}
//                 date={date}
//                 title={title}
//                 price={price}
//                 to={to}
//                 des={des}
//                 gap={gap}
//                 availability={availability}
//             />
//         </div>
//     })
// }