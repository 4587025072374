import React from "react";
import {Link} from "react-router-dom";

function DestinationCart(props) {
    const {title, place, hotel, image, id} = props
    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    };
    if (id !== 0)
        return (
            <>
                <Link
                    onClick={scrollTop}
                    to={{
                        pathname: `${process.env.PUBLIC_URL}/tour-images/${id}`,
                    }}
                >
                    <div className="destination-card-style-two mb-0">
                        <div className="d-card-thumb">
                            <img src={image} alt="CartIMG"/>
                        </div>
                        <div className="d-card-content">
                            <h4 className="destination-title">
                                {title}
                            </h4>
                            <div className="place-count">
                                <span>{place}</span> Place
                            </div>
                        </div>
                    </div>
                </Link>
            </>
        );

    else
        return (
            <>
                <div className="destination-card-style-two mb-0">
                    <div className="d-card-thumb">
                        <img src={image} alt="CartIMG"/>
                    </div>
                    <div className="d-card-content">
                        <h4 className="destination-title">
                            {title}
                        </h4>
                        <div className="place-count">
                            <span>{place}</span> Place
                        </div>
                    </div>
                </div>
            </>
        );

}

export default DestinationCart;
