import React from 'react';
import {Link} from "react-router-dom";

const FooterBar2 = () => {
    return (<div className="footer-contact-wrapper">
        <h5>Contact Us:</h5>
        <ul className="footer-contact-list">
            <li>
                <i className="bi bi-telephone-x"/>{" "}
                <a rel="noopener noreferrer" href="tel:+92-321-8542224">
                    +92-321-8542224
                </a>
            </li>
            <li>
                <i className="bi bi-envelope-open"/>{" "}
                <a href="mailto:ali@zoongroup.com">ali@zoongroup.com</a>
            </li>
            <li>
                <i className="bi bi-geo-alt"/>{" "}
                <Link to={"#"}>Zoon Safari 23-B Haider Road Rawalpindi</Link>
            </li>
        </ul>
    </div>)
}

export default FooterBar2;