import React from "react";
import {useLocation} from "react-router-dom";
import { UserContextProvider } from "../userContext/UserContextFile";
import Footer from "./common/Footer";
import Header from "./common/Header";
import HHeader from "./common/HHeader";
import HFooter from "./common/HFooter";

function App(props) {
  const { pathname } = useLocation();
  const path = pathname.split('/')[1];

  const getDynamicHeader = (path) => {
    switch(path){
      case 'package-details' :
        return <Header />
      case 'hunting-details' :
        return <HHeader />
      default :
        return <Header />
    }
  }

  const getDynamicFooter = (path) => {
    switch(path) {
      case 'package-details' :
        return <Footer className="footer-area mt-110" />
      case 'hunting-details' :
        return <HFooter className="footer-area mt-110" />
      default :
        return <Footer className="footer-area mt-110" />
    }
  }


  return (
    <>
    <UserContextProvider >
      {getDynamicHeader(path)}
      {props.children}
      {getDynamicFooter(path)}

    </UserContextProvider>
    </>
  );
}

export default App;
