import React from 'react'
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Layout from "./components/App";
import Main from "./components/layout/Main";
import Tours from './components/layout/Tours'
import Hunting from "./components/layout/Hunting"
import FineArt from "./components/layout/FineArt";
import PowerSolution from "./components/layout/PowerSolution";
import About from "./components/pages/about/About";
import BlogSidebar from "./components/pages/blog/BlodSidebar/BlogSidebar";
import BlogDetailsPage from "./components/pages/blog/blogdetails/BlogDetailsPage";
import BlogStandard from "./components/pages/blog/BlogStandard/BlogStandard";
import Contact from "./components/pages/contact/Contact";
import DestinationDetails from "./components/pages/destinationDetails/DestinationDetails";
import Destinations from "./components/pages/destinetion/Destinatination";
import Error from "./components/pages/error/Error";
import FaqPage from "./components/pages/faq/FaqPage";
import GuidePage from "./components/pages/guide/GuidePage";
import HPackageDetails from "./components/pages/huntingDetails/HPackageDetails";
import PackageDetails from "./components/pages/packageDetails/PackageDetails";
import PackageGrid from "./components/pages/packageGrid/PackageGrid";
import PackageSidebar from "./components/pages/packageSidebar/PackageSIdebar";
import PackageStandard from "./components/pages/packageStandard/PackageStandard";
import GallaryPage from "./components/pages/gallary/GallaryPage";
import BlogGridPage from "./components/pages/blog/BlogGrid/BlogGridPage";
import SimpleReactLightbox from "simple-react-lightbox";

// import all css
import "./index.css";
import TourImages from "./components/pages/tour-images/TourImages";
import HuntingImages from "./components/pages/hunting-images/HImages";

/*
 * Version :ZoonSafari
 * Event : Rendering all content to web.
 * Actions: Define all routes and page.
 * @return html
 * */
// default Warning Error hide



function Root() {
  return (
    <>
    <BrowserRouter basename="/">
      <Switch>
        {/*ZoonSafari Welcome Page*/}
        <Route exact path="/" component={Main} />

        {/*ZoonSafari Tours*/}
        <Route exact path="/tours" component={Tours} />

        {/*ZoonSafari Hunting*/}
        <Route exact path="/hunting" component={Hunting} />

        <Route
              exact
              path={`${process.env.PUBLIC_URL}/hunting-images/:id`}
              component={HuntingImages}
          />

        {/*ZoonSaraf */}
        <Route exact path="/FineArt" component={FineArt} />

        {/*main layout*/}
        <Route exact path="/PowerSolution" component={PowerSolution} />
        {/* all inner page load layout component */}
        <Layout>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about`}
            component={About}
          />

          {/* desination pages */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/destinations`}
            component={Destinations}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/destination-details/:id`}
            component={DestinationDetails}
          />

          {/* all tour_card pages component */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/tours/:cat`}
            component={PackageGrid}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/package-sidebar`}
            component={PackageSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/package-standard`}
            component={PackageStandard}
          />
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/hunting-details/:id`}
              component={HPackageDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/package-details/:id`}
            component={PackageDetails}
          />

          <Route
              exact
              path={`${process.env.PUBLIC_URL}/tour-images/:id`}
              component={TourImages}
          />

          {/* all blog pages */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog`}
            component={BlogGridPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-details`}
            component={BlogDetailsPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-standard`}
            component={BlogStandard}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-sidebar`}
            component={BlogSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/guide`}
            component={GuidePage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/faq`}
            component={FaqPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/gallary`}
            component={GallaryPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/error`}
            component={Error}
          />
        </Layout>
      </Switch>
    </BrowserRouter>
  </>
  )
}

export default Root
ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <Root />
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);
