import React from "react";
import HeroArea from "./HeroArea";
import PackageArea from "./PackageArea";
import DestinationArea from "./DestinationArea";
import NewsLetterArea from "./NewsLetterArea";
import GallaryArea from "./GallaryArea";

import TestimonialArea from "../../common/TestimonialArea";
import LatestNews from "./LatestNews";
import OfferArea from "./OfferArea";
import TourGideArea from "./TourGideArea";

function HomePage() {
    return (
        <>
            <HeroArea/>
            <PackageArea/>
            <DestinationArea/>
            <NewsLetterArea/>
            <GallaryArea/>
            {/*<OfferArea />*/}
            {/*<TestimonialArea />*/}
            {/*<TourGideArea />*/}
            {/*<LatestNews />*/}
        </>
    );
}

export default HomePage;
