import React from 'react';
import {Link} from "react-router-dom";

const FooterBar1 = () => {

    // smooth scrol
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (<div className="row justify-content-center g-4">
        <div className="col-lg-4">
            <div className="footer-about text-lg-start text-center">
                <p>
                    Wherever you go, go with all your heart
                </p>
                <div className="footer-social-wrap">
                    <h5>Follow Us On:</h5>
                    <ul className="footer-social-links justify-content-lg-start justify-content-center">
                        <li>
                            <a
                                rel="noopener noreferrer"
                                href="https://www.instagram.com/"
                                target="_blank"
                            >
                                <i className="bx bxl-instagram"/>
                            </a>
                        </li>
                        <li>
                            <a
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/"
                                target="_blank"
                            >
                                <i className="bx bxl-facebook"/>
                            </a>
                        </li>
                        <li>
                            <a
                                rel="noopener noreferrer"
                                href="https://www.twitter.com/"
                                target="_blank"
                            >
                                <i className="bx bxl-twitter"/>
                            </a>
                        </li>
                        <li>
                            <a
                                rel="noopener noreferrer"
                                href="https://www.whatsapp.com/"
                                target="_blank"
                            >
                                <i className="bx bxl-whatsapp"/>
                            </a>
                        </li>
                        <li>
                            <a
                                rel="noopener noreferrer"
                                href="https://www.pinterest.com/"
                                target="_blank"
                                to={"#"}
                            >
                                <i className="bx bxl-pinterest-alt"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="col-lg-2 col-md-4">
            <div className="footer-widget">
                <h3 className="footer-widget-title">Quick Link</h3>
                <ul className="footer-links">
                    <li>
                        <Link
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/about`}
                        >
                            About Us
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/tours/all`}
                        >
                            Tour Packages
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/destinations`}
                        >
                            Destination
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
        <div className="col-lg-2 col-md-4">
            <div className="footer-widget">
                <h4 className="footer-widget-title">Tours</h4>
                <ul className="footer-links">
                    <li>
                        <Link
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/tours/adventure`}
                        >
                            Adventure
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/tours/cultural`}
                        >
                            Cultural
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/tours/sightseen`}
                        >
                            SightSeen
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
        <>
            <div className="col-lg-4 col-md-8">
                {/*<SRLWrapper>*/}
                <div className="footer-widget">
                    <h4 className="footer-widget-title text-center">
                        Gallery
                    </h4>
                    <div className="footer-gallary-grid">
                        <div className="footer-gallary-item">
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg_1.png"}
                                 alt="footerIMG"/>
                        </div>
                        <div className="footer-gallary-item">
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg_2.png"}
                                 alt="footerIMG"/>
                        </div>
                        <div className="footer-gallary-item">
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg_3.png"}
                                 alt="footerIMG"/>
                        </div>
                        <div className="footer-gallary-item">
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg_4.png"}
                                 alt="footerIMG"/>
                        </div>
                        <div className="footer-gallary-item">
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg_5.png"}
                                 alt="footerIMG"/>
                        </div>
                        <div className="footer-gallary-item">
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg_6.png"}
                                 alt="footerIMG"/>
                        </div>
                    </div>
                </div>
                {/*</SRLWrapper>*/}
            </div>
        </>
    </div>)
}

export default FooterBar1;