import React from "react";

import {Swiper, SwiperSlide} from "swiper/react";

// import Swiper core and required modules
import SwiperCore, {Autoplay, Navigation} from "swiper";
import HomeOneDestinationCart from "./HomeOneDestinationCart";

import destinations from '../../../database/destinations'
// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

function DestinationArea() {
    const destinationSlider = {
        slidesPerView: 1,
        speed: 2000,
        spaceBetween: 24,
        loop: true,
        roundLengths: true,
        centeredSlides: true,
        autoplay: {
            delay: 3000,
        },
        navigation: {
            nextEl: ".testi-next",
            prevEl: ".testi-prev",
        },
        breakpoints: {
            480: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            992: {
                slidesPerView: 4,
            },
            1200: {
                slidesPerView: 5,
            },
        },
    };
    return (
        <>
            {/* =============== destination area start =============== */}
            <div className="destination-area destination-style-one pt-110 ">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="section-head-alpha">
                                <h2>Explore Top Destination</h2>
                                <p>
                                    Everything in nature invites us constantly to be what we are.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="slider-arrows text-center d-lg-flex d-none justify-content-end mb-3">
                                <div
                                    className="testi-prev custom-swiper-prev"
                                    tabIndex={0}
                                    role="button"
                                    aria-label="Previous slide"
                                >
                                    <i className="bi bi-chevron-left"/>
                                </div>
                                <div
                                    className="testi-next custom-swiper-next"
                                    tabIndex={0}
                                    role="button"
                                    aria-label="Next slide"
                                >
                                    <i className="bi bi-chevron-right"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0 overflow-hidden">
                    <Swiper
                        {...destinationSlider}
                        className="swiper destination-slider-one"
                    >
                        <div className="swiper-wrapper">

                            {destinations && destinations.map((dest, index) => {
                                const {title, place, hotel, image} = dest
                                return (
                                    <SwiperSlide className="swiper-slide">
                                        <HomeOneDestinationCart
                                            id={index + 1}
                                            image={image}
                                            title={title}
                                            place={place}
                                            hotel={hotel}
                                        />
                                    </SwiperSlide>
                                )
                            })}
                        </div>
                    </Swiper>
                </div>
            </div>
            {/* =============== destination area  end =============== */}
        </>
    );
}

export default DestinationArea;
