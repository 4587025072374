import React from "react";
import { Link } from "react-router-dom";
import HHeader from "./HHeader";
import HImagesTab from "./HImagesTab";

function HImagesWrap({data}) {
  console.log("Data in Wrapper: ", data)

  return (
    <>
      <div className="package-details-wrapper pt-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tour-package-details">
                <HHeader data={data}/>
                <HImagesTab id={data.id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HImagesWrap;
