import React from "react";

const tours = [
    {
        title: 'Skardu & Shigar Valley',
        availability: 'June - October',
        date: '7 Days - 6 nights',
        price: '$120.50',
        gap: '0px',
        to: 1,
        image: '/images/tour_card/tour-skardu-card.png',
        category: '',
        status: 'popular',
        active: true
    },
    {   //temporary
        title: 'Swat & Kalam Valley',
        availability: 'Around the year',
        date: '5 Days - 4 nights',
        price: '$120.50',
        gap: '0px',
        to: 2,
        image: '/images/tour_card/tour-swat-card.png',
        category: '',
        status: 'popular',
        active: false
    },
    {
        title: 'Buddhist Trail',
        availability: 'Around the year',
        date: '8 Days - 7 nights',
        price: '$120.50',
        gap: '0px',
        to: 3,
        image: '/images/tour_card/tour-buddhist-card.png',
        category: '',
        status: 'popular',
        active: true
    },
    {   //temporary
        title: 'Mughals & Gandhara _ historical journey',
        availability: 'Around the year',
        date: '9 Days - 8 nights',
        price: '$120.50',
        gap: '0px',
        to: 4,
        image: '/images/tour_card/tour-gandhara-card.png',
        category: '',
        status: 'popular',
        active: false
    },
    {
        title: 'Exquisite Naran & Shogran Tour',
        availability: 'May - October',
        date: '5 Days - 4 nights',
        price: '$120.50',
        gap: '0px',
        to: 5,
        image: '/images/tour_card/tour-naran-card.png',
        category: '',
        status: 'popular',
        active: true
    },
    {
        title: 'HUNZA Valley in Autumn',
        availability: 'August - November',
        date: '7 Days - 6 nights',
        price: '$88.00',
        gap: '0px',
        to: 6,
        image: '/images/tour_card/tour-hunza-card.png',
        category: '',
        status: 'popular',
        active: true

    },
    {
        title: 'CHITRAL - HUNZA',
        availability: 'April -October',
        date: '6 Days - 5 nights',
        price: '$102.00',
        gap: '0px',
        to: 7,
        image: '/images/tour_card/tour-chitral-card.png',
        category: '',
        status: 'popular',
        active: true

    },
    {
        title: 'HUNZA - SWAT',
        availability: '___',
        date: '15 Day- & 14 nights',
        price: '$88.00',
        to: 8,
        gap: '0px',
        image: '/images/tour_card/tour-hunza-swat-card.png',
        category: 'cultural',
        status: 'popular',
        active: true
    },
    {
        title: "NORTH PAKISTAN",
        availability: '___',
        date: "16 Days - 15 nights",
        price: "$98.00",
        to: 9,
        gap: '0px',
        image: "/images/tour_card/tour-north-pakistan-card.png",
        category: 'cultural',
        status: 'popular',
        active: true
    },
    {
        title: 'CHITRAL - HUNZA',
        availability: '___',
        date: '14 DayS & 13 nights',
        price: '$102.00',
        to: 10,
        des: '',
        gap: '0px',
        image: '/images/tour_card/tour-chitral-hunza-card.png',
        category: 'cultural',
        status: 'popular',
        active: true
    },
    {
        title: 'GILGIT BALTISTAN',
        availability: '___',
        date: '15 Days & 15 nights',
        price: '$99.50',
        to: 11,
        gap: '0px',
        image: '/images/tour_card/tour-gilgit-balkistan-card.png',
        category: 'cultural',
        status: 'popular',
        active: true
    },
    {
        title: 'SHIMSHAL PASS',
        availability: '___',
        date: '14 Days - 13 nights',
        price: '$120.50',
        to: 12,
        gap: '0px',
        image: '/images/tour_card/tour-shimshal-pass-card.png',
        category: 'adventure',
        status: 'popular',
        active: true
    },
    {
        title: 'BIAFO HISPER',
        availability: '___',
        date: '20 Days & 19 nights',
        price: '$110.50',
        to: 13,
        gap: '0px',
        image: '/images/tour_card/tour-biafo-hisper-card.png',
        category: 'adventure',
        status: 'popular',
        active: true
    },
    {
        title: 'GONDOGORO LA PASS',
        availability: '___',
        date: '21 Days & 20 nights',
        price: '$110.50',
        to: 14,
        gap: '0px',
        image: '/images/tour_card/tour-gondogoro-la-pass-card.png',
        category: 'adventure',
        status: 'popular',
        active: true
    },
    {
        title: 'PATUNDAS - RUSHLAKE',
        availability: '___',
        date: '17 Days & 16 nights',
        price: '$110.50',
        to: 15,
        gap: '0px',
        image: '/images/tour_card/tour-patundas-rush-lake-card.png',
        category: 'adventure',
        status: 'popular',
        active: true
    },
    {
        title: 'Baluchistan Adventure',
        availability: 'Around the year',
        date: '9 Days - 8 nights',
        price: '$120.50',
        gap: '0px',
        to: 16,
        image: '/images/tour_card/tour-baluchistan-card.png',
        category: 'adventure',
        status: 'popular',
        active: true
    },
]

export default tours