import React from "react";
import { Link } from "react-router-dom";

function HomeOneDestinationCart(props) {
  const {title, place, hotel, image, id} = props
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="destination-card-style-one">
       {/* <Link
            to={`${process.env.PUBLIC_URL}/destination-details/${id}`}
            onClick={scrollTop}
        >*/}
        <div className="d-card-thumb">
            <img src={process.env.PUBLIC_URL + image} alt="images" />
        </div>
        <div className="d-card-overlay">
          <div className="d-card-content">
            <h3 className="d-card-title">
                {title}
            </h3>
            <div className="d-card-info">
              <div className="place-count">
                <span>{place}</span> Place
              </div>
              <div className="hotel-count">
                <span>{hotel}</span> Hotel
              </div>
            </div>
            <ul className="d-rating">
              <li>
                <i className="bi bi-star-fill" />
              </li>
              <li>
                <i className="bi bi-star-fill" />
              </li>
              <li>
                <i className="bi bi-star-fill" />
              </li>
              <li>
                <i className="bi bi-star-fill" />
              </li>
              <li>
                <i className="bi bi-star-fill" />
              </li>
            </ul>
          </div>
        </div>
        {/*</Link>*/}
      </div>
    </>
  );
}

export default HomeOneDestinationCart;
