import React, {useRef, useState} from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import emailjs from "@emailjs/browser";

function HWidgetForm({data}) {
    const {id, title} = data
    const [submittingDataLoader, setSubmittingDataLoader] = useState(false)
    const YOUR_SERVICE_ID = 'service_mwt0g1p';
    const YOUR_TEMPLATE_ID = 'template_my8q40t';
    const YOUR_PUBLIC_KEY = 'OLrPv-_2c4o-kDsjT';
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setSubmittingDataLoader(true)
        emailjs.sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, form.current, YOUR_PUBLIC_KEY)
            .then((result) => {
                setSubmittingDataLoader(false)
                e.target.reset();
            }, (error) => {
                console.log(error.text);
            });
    };


    return (
        <>
            <aside className="package-widget-style-2 widget-form">
                <div className="widget-title text-center d-flex justify-content-between">
                    <Backdrop
                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                        open={submittingDataLoader}
                        onClick={() => setSubmittingDataLoader(false)}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <h4>Book This Hunting Package</h4>
                </div>
                <div className="widget-body">
                    <form
                        ref={form}
                        onSubmit={sendEmail}
                        id="booking-form"
                    >
                        <div className="booking-form-wrapper">
                            <div className="custom-input-group" style={{display:'none'}} >
                                <input
                                    readOnly={true}
                                    id="tour_id"
                                    name="tour_id"
                                    value={id}
                                    type="text"
                                />
                            </div>
                            <div className="custom-input-group" >
                                <input
                                    readOnly={true}
                                    id="title"
                                    name="title"
                                    value={title}
                                    type="text"
                                />
                            </div>
                            <div className="custom-input-group">
                                <input
                                    id="name"
                                    name="name"
                                    required={true}
                                    type="text"
                                    placeholder="Your Full Name"
                                />
                            </div>
                            <div className="custom-input-group">
                                <input
                                    id="email"
                                    name="email"
                                    required={true}
                                    type="email"
                                    placeholder="Your Email"
                                />
                            </div>
                            <div className="custom-input-group">
                                <input
                                    required={true}
                                    id="phone"
                                    name="phone"
                                    type="tel"
                                    placeholder="Phone"

                                />
                            </div>
                            <div className="custom-input-group">
                                <input
                                    id="subject"
                                    name="subject"
                                    type="text"
                                    placeholder="Subject"

                                />
                            </div>
                            <div className="custom-input-group">
                             <textarea
                                 required={true}
                                 name="message"
                                 id="message"
                                 cols={20}
                                 rows={7}
                                 placeholder="Your message"
                             />
                            </div>
                            <div className="custom-input-group">
                                <div className="submite-btn">
                                    <button type="submit">Book Now</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </aside>
        </>
    );
}

export default HWidgetForm;