import React from "react";
import {Link} from "react-router-dom";

function PackageCard(props) {
    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    };

    const {image, date, title, price, to, gap, des, availability} = props;

    return (
        <>
            <div className="package-card-alpha">
                <div className="package-thumb">
                    <Link
                        onClick={scrollTop}
                        to={{
                            pathname: `${process.env.PUBLIC_URL}/package-details/${to}`,
                        }}
                    >
                        <img src={image} alt="images"/>
                    </Link>
                    <p className="card-lavel">
                        <i className="bi bi-clock"/> <span>{date}</span>
                    </p>
                </div>
                <div className="package-card-body">
                    {gap && <div style={{height: gap}}></div>}
                    {des && <p>{des}</p>}
                    <h3 className="p-card-title">
                        <Link
                            onClick={scrollTop}
                            to={{
                                pathname: `${process.env.PUBLIC_URL}/package-details/${to}`,
                            }}
                        >
                            {title}
                        </Link>
                    </h3>
                    <p style={{marginTop:'5px'}}><span style={{fontWeight:'600'}} >Availability:</span> {availability} </p>
                    <div className="p-card-bottom">
                        <div className="book-btn">
                            <Link
                                onClick={scrollTop}
                                to={{
                                    pathname: `${process.env.PUBLIC_URL}/package-details/${to}`,
                                }}
                            >
                                Book Now <i className="bx bxs-right-arrow-alt"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PackageCard;
