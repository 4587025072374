import React from "react";
import { Link } from "react-router-dom";
import WidgetForm from "./WidgetForm";
import {ContactUs} from "./ContactUs";
import WidgetPackageOffer from "./WidgetPackageOffer";
import WidgetPackageTag from "./WidgetPackageTag";
import PackageHeader from "./PackageHeader";
import PackageDetailsTab from "./PackageDetailsTab";

function PackageDetailsWrap({data}) {
  console.log("Data in Wrapper: ", data)

  return (
    <>
      <div className="package-details-wrapper pt-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="tour-package-details">
                <PackageHeader data={data}/>
                <PackageDetailsTab data={data} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="package-sidebar">
                <WidgetForm data={data}/>
                {/*<HContactUs />*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackageDetailsWrap;
