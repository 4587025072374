import React, {useEffect, useState} from "react";
import Footer from "../common/Footer";
import TopBar from "../common/TopBar";
import Main from "../pages/MainPage/Main";
import Loading from "../common/Loading";

function MainPage() {
    const [load, setLoad] = useState(false);
    useEffect(() => {
        setLoad(true);
        setTimeout(() => {
            setLoad(false);
        }, 3000);
    }, []);
    return (
        <>
            {load ? (
                <Loading/>
            ) : (
                <>
                    <TopBar message='Welcome To Zoon Group'/>
                    <Main/>
                    <Footer className="footer-area"/>
                </>
            )}
        </>
    );
}

export default MainPage;
