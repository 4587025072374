import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
function GallaryArea() {
  return (
    <>
      <SRLWrapper>
        <div className="gallary-area gallary-style-one pt-110">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-head-alpha text-center mx-auto">
                  <h2>Travel Gallery</h2>
                  <p>
                    Randomly clicked during tour
                  </p>
                </div>
              </div>
            </div>
            <div className="gallery-wrapper">
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="gallary-item">
                    <img
                      src={process.env.PUBLIC_URL + "/images/gallary/g_xl1.png"}
                      alt="ImageGallery"
                    />
                    <a
                      className="gallary-item-overlay"
                      href={
                        process.env.PUBLIC_URL +
                        "/images/gallary/g_fullscreen04.png"
                      }
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/gallary/g_xl1.png"
                        }
                        alt="ImageGallery"
                      />
                    </a>
                  </div>
                  <div className="gallary-item">
                    <img
                      src={process.env.PUBLIC_URL + "/images/gallary/g_md1.png"}
                      alt="ImageGallery"
                    />
                    <a
                      className="gallary-item-overlay"
                      href={
                        process.env.PUBLIC_URL +
                        "/images/gallary/g_fullscreen06.png"
                      }
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/gallary/g_md1.png"
                        }
                        alt="ImageGallery"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="gallary-item">
                    <img
                      src={process.env.PUBLIC_URL + "/images/gallary/g_sm1.png"}
                      alt="ImageGallery"
                    />
                    <a
                      className="gallary-item-overlay"
                      href={
                        process.env.PUBLIC_URL +
                        "/images/gallary/g_fullscreen02.png"
                      }
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/gallary/g_sm1.png"
                        }
                        alt="ImageGallery"
                      />
                    </a>
                  </div>
                  <div className="gallary-item">
                    <img
                      src={process.env.PUBLIC_URL + "/images/gallary/g_sm2.png"}
                      alt="ImageGallery"
                    />
                    <a
                      className="gallary-item-overlay"
                      href={
                        process.env.PUBLIC_URL +
                        "/images/gallary/g_fullscreen07.png"
                      }
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/gallary/g_sm2.png"
                        }
                        alt="ImageGallery"
                      />
                    </a>
                  </div>
                  <div className="gallary-item">
                    <img
                      src={process.env.PUBLIC_URL + "/images/gallary/g_md2.png"}
                      alt="ImageGallery"
                    />
                    <a
                      className="gallary-item-overlay"
                      href={
                        process.env.PUBLIC_URL +
                        "/images/gallary/g_fullscreen05.png"
                      }
                    >
                      <img
                      src={process.env.PUBLIC_URL + "/images/gallary/g_md2.png"}
                      alt="ImageGallery"
                    />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="gallary-item">
                    <img
                      src={process.env.PUBLIC_URL + "/images/gallary/g_xl2.png"}
                      alt="ImageGallery"
                    />
                    <a
                      className="gallary-item-overlay"
                      href={
                        process.env.PUBLIC_URL +
                        "/images/gallary/g_fullscreen01.png"
                      }
                    >
                       <img
                      src={process.env.PUBLIC_URL + "/images/gallary/g_xl2.png"}
                      alt="ImageGallery"
                    />
                    </a>
                  </div>
                  <div className="gallary-item">
                    <img
                      src={process.env.PUBLIC_URL + "/images/gallary/g_md3.png"}
                      alt="ImageGallery"
                    />
                    <a
                      className="gallary-item-overlay"
                      href={
                        process.env.PUBLIC_URL +
                        "/images/gallary/g_fullscreen03.png"
                      }
                    >
                      <img
                      src={process.env.PUBLIC_URL + "/images/gallary/g_md3.png"}
                      alt="ImageGallery"
                    />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SRLWrapper>
    </>
  );
}

export default GallaryArea;
