const HImages = [
  [
    [
      {
        1: "/images/hunting/punjab_urial/image_1.jpg",
        2: "/images/hunting/punjab_urial/image_1.jpg",
      },
      {
        1: "/images/hunting/punjab_urial/image_2.jpg",
        2: "/images/hunting/punjab_urial/image_2.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/punjab_urial/image_3.jpg",
        2: "/images/hunting/punjab_urial/image_3.jpg",
      },
      {
        1: "/images/hunting/punjab_urial/image_4.jpg",
        2: "/images/hunting/punjab_urial/image_4.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/punjab_urial/image_5.jpg",
        2: "/images/hunting/punjab_urial/image_5.jpg",
      },
      {
        1: "/images/hunting/punjab_urial/image_6.jpg",
        2: "/images/hunting/punjab_urial/image_6.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/sindh_ibex/image_7.jpg",
        2: "/images/hunting/sindh_ibex/image_7.jpg",
      },
      {
        1: "/images/hunting/sindh_ibex/image_8.jpg",
        2: "/images/hunting/sindh_ibex/image_8.jpg",
      },
    ],
  ],
  [
    [
      {
        1: "/images/hunting/sindh_ibex/image_1.jpg",
        2: "/images/hunting/sindh_ibex/image_1.jpg",
      },
      {
        1: "/images/hunting/sindh_ibex/image_2.jpg",
        2: "/images/hunting/sindh_ibex/image_2.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/sindh_ibex/image_3.jpg",
        2: "/images/hunting/sindh_ibex/image_3.jpg",
      },
      {
        1: "/images/hunting/sindh_ibex/image_4.jpg",
        2: "/images/hunting/sindh_ibex/image_4.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/sindh_ibex/image_5.jpg",
        2: "/images/hunting/sindh_ibex/image_5.jpg",
      },
      {
        1: "/images/hunting/sindh_ibex/image_6.jpg",
        2: "/images/hunting/sindh_ibex/image_6.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/sindh_ibex/image_7.jpg",
        2: "/images/hunting/sindh_ibex/image_7.jpg",
      },
      {
        1: "/images/hunting/sindh_ibex/image_8.jpg",
        2: "/images/hunting/sindh_ibex/image_8.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/sindh_ibex/image_9.jpg",
        2: "/images/hunting/sindh_ibex/image_9.jpg",
      },
      {
        1: "/images/hunting/sindh_ibex/image_10.jpg",
        2: "/images/hunting/sindh_ibex/image_10.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/sindh_ibex/image_11.jpg",
        2: "/images/hunting/sindh_ibex/image_11.jpg",
      },
      {
        1: "/images/hunting/sindh_ibex/image_12.jpg",
        2: "/images/hunting/sindh_ibex/image_12.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/sindh_ibex/image_13.jpg",
        2: "/images/hunting/sindh_ibex/image_13.jpg",
      },
      {
        1: "/images/hunting/sindh_ibex/image_14.jpg",
        2: "/images/hunting/sindh_ibex/image_14.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/sindh_ibex/image_15.jpg",
        2: "/images/hunting/sindh_ibex/image_15.jpg",
      },
      {
        1: "/images/hunting/sindh_ibex/image_16.jpg",
        2: "/images/hunting/sindh_ibex/image_16.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/sindh_ibex/image_17.jpg",
        2: "/images/hunting/sindh_ibex/image_17.jpg",
      },
      {
        1: "/images/hunting/sindh_ibex/image_18.jpg",
        2: "/images/hunting/sindh_ibex/image_18.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/sindh_ibex/image_19.jpg",
        2: "/images/hunting/sindh_ibex/image_19.jpg",
      },
    ],
  ],
  [
    [
      {
        1: "/images/hunting/blue_sheep/image_1.jpg",
        2: "/images/hunting/blue_sheep/image_1.jpg",
      },
      {
        1: "/images/hunting/blue_sheep/image_2.jpg",
        2: "/images/hunting/blue_sheep/image_2.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/blue_sheep/image_3.jpg",
        2: "/images/hunting/blue_sheep/image_3.jpg",
      },
      {
        1: "/images/hunting/blue_sheep/image_4.jpg",
        2: "/images/hunting/blue_sheep/image_4.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/blue_sheep/image_5.jpg",
        2: "/images/hunting/blue_sheep/image_5.jpg",
      },
      {
        1: "/images/hunting/blue_sheep/image_6.jpg",
        2: "/images/hunting/blue_sheep/image_6.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/blue_sheep/image_7.jpg",
        2: "/images/hunting/blue_sheep/image_7.jpg",
      },
      {
        1: "/images/hunting/blue_sheep/image_8.jpg",
        2: "/images/hunting/blue_sheep/image_8.jpg",
      },
    ],
  ],
  [
    [
      {
        1: "/images/hunting/blandord_Urial/image_1.jpg",
        2: "/images/hunting/blandord_Urial/image_1.jpg",
      },
      {
        1: "/images/hunting/blandord_Urial/image_2.jpg",
        2: "/images/hunting/blandord_Urial/image_2.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/blandord_Urial/image_3.jpg",
        2: "/images/hunting/blandord_Urial/image_3.jpg",
      },
      {
        1: "/images/hunting/blandord_Urial/image_4.jpg",
        2: "/images/hunting/blandord_Urial/image_4.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/blandord_Urial/image_5.jpg",
        2: "/images/hunting/blandord_Urial/image_5.jpg",
      },
      {
        1: "/images/hunting/blandord_Urial/image_6.jpg",
        2: "/images/hunting/blandord_Urial/image_6.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/blandord_Urial/image_7.jpg",
        2: "/images/hunting/blandord_Urial/image_7.jpg",
      },
      {
        1: "/images/hunting/blandord_Urial/image_8.jpg",
        2: "/images/hunting/blandord_Urial/image_8.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/blandord_Urial/image_9.jpg",
        2: "/images/hunting/blandord_Urial/image_9.jpg",
      },
      {
        1: "/images/hunting/blandord_Urial/image_10.jpg",
        2: "/images/hunting/blandord_Urial/image_10.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/blandord_Urial/image_11.jpg",
        2: "/images/hunting/blandord_Urial/image_11.jpg",
      },
    ],
  ],
  [
    [
      {
        1: "/images/hunting/himalayan_ibex/image_1.jpg",
        2: "/images/hunting/himalayan_ibex/image_1.jpg",
      },
      {
        1: "/images/hunting/himalayan_ibex/image_2.jpg",
        2: "/images/hunting/himalayan_ibex/image_2.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/himalayan_ibex/image_3.jpg",
        2: "/images/hunting/himalayan_ibex/image_3.jpg",
      },
      {
        1: "/images/hunting/himalayan_ibex/image_4.jpg",
        2: "/images/hunting/himalayan_ibex/image_4.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/himalayan_ibex/image_5.jpg",
        2: "/images/hunting/himalayan_ibex/image_5.jpg",
      },
      {
        1: "/images/hunting/himalayan_ibex/image_6.jpg",
        2: "/images/hunting/himalayan_ibex/image_6.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/himalayan_ibex/image_7.jpg",
        2: "/images/hunting/himalayan_ibex/image_7.jpg",
      },
      {
        1: "/images/hunting/himalayan_ibex/image_8.jpg",
        2: "/images/hunting/himalayan_ibex/image_8.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/himalayan_ibex/image_9.jpg",
        2: "/images/hunting/himalayan_ibex/image_9.jpg",
      },
      {
        1: "/images/hunting/himalayan_ibex/image_10.jpg",
        2: "/images/hunting/himalayan_ibex/image_10.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/himalayan_ibex/image_11.jpg",
        2: "/images/hunting/himalayan_ibex/image_11.jpg",
      },
      {
        1: "/images/hunting/himalayan_ibex/image_12.jpg",
        2: "/images/hunting/himalayan_ibex/image_12.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/himalayan_ibex/image_13.jpg",
        2: "/images/hunting/himalayan_ibex/image_13.jpg",
      },
      {
        1: "/images/hunting/himalayan_ibex/image_14.jpg",
        2: "/images/hunting/himalayan_ibex/image_14.jpg",
      },
    ],
  ],
  [
    [
      {
        1: "/images/hunting/astor_markhor/image_1.jpg",
        2: "/images/hunting/astor_markhor/image_1.jpg",
      },
      {
        1: "/images/hunting/astor_markhor/image_2.jpg",
        2: "/images/hunting/astor_markhor/image_2.jpg",
      },
    ],
  ],
  [],
  [],
  [
    [
      {
        1: "/images/hunting/afghan_urial/image_1.jpg",
        2: "/images/hunting/afghan_urial/image_1.jpg",
      },
      {
        1: "/images/hunting/afghan_urial/image_2.jpg",
        2: "/images/hunting/afghan_urial/image_2.jpg",
      },
    ],
    [
      {
        1: "/images/hunting/afghan_urial/image_3.jpg",
        2: "/images/hunting/afghan_urial/image_3.jpg",
      },
      {
        1: "/images/hunting/afghan_urial/image_4.jpg",
        2: "/images/hunting/afghan_urial/image_4.jpg",
      },
    ],
  ],
  [
    [
      {
        1: "/images/hunting/boar/boar_hunt_1.jpeg",
        2: "/images/hunting/boar/boar_hunt_1.jpeg",
      },
      {
        1: "/images/hunting/boar/boar_hunt_2.jpeg",
        2: "/images/hunting/boar/boar_hunt_2.jpeg",
      },
    ],[
      {
        1: "/images/hunting/boar/boar_hunt_3.jpeg",
        2: "/images/hunting/boar/boar_hunt_3.jpeg",
      },
      {
        1: "/images/hunting/boar/boar_hunt_4.jpeg",
        2: "/images/hunting/boar/boar_hunt_4.jpeg",
      },
    ],[
      {
        1: "/images/hunting/boar/boar_hunt_5.jpeg",
        2: "/images/hunting/boar/boar_hunt_5.jpeg",
      },
      {
        1: "/images/hunting/boar/boar_hunt_6.jpeg",
        2: "/images/hunting/boar/boar_hunt_6.jpeg",
      },
    ],[
      {
        1: "/images/hunting/boar/boar_hunt_7.jpeg",
        2: "/images/hunting/boar/boar_hunt_7.jpeg",
      },
      {
        1: "/images/hunting/boar/boar_hunt_8.jpeg",
        2: "/images/hunting/boar/boar_hunt_8.jpeg",
      },
    ],[
      {
        1: "/images/hunting/boar/boar_hunt_9.jpeg",
        2: "/images/hunting/boar/boar_hunt_9.jpeg",
      },
      {
        1: "/images/hunting/boar/boar_hunt_10.jpeg",
        2: "/images/hunting/boar/boar_hunt_10.jpeg",
      },
    ],[
      {
        1: "/images/hunting/boar/boar_hunt_11.jpeg",
        2: "/images/hunting/boar/boar_hunt_11.jpeg",
      },
      {
        1: "/images/hunting/boar/boar_hunt_12.jpeg",
        2: "/images/hunting/boar/boar_hunt_12.jpeg",
      },
    ],[
      {
        1: "/images/hunting/boar/boar_hunt_13.jpeg",
        2: "/images/hunting/boar/boar_hunt_13.jpeg",
      },
      {
        1: "/images/hunting/boar/boar_hunt_14.jpeg",
        2: "/images/hunting/boar/boar_hunt_14.jpeg",
      },
    ],[
      {
        1: "/images/hunting/boar/boar_hunt_15.jpeg",
        2: "/images/hunting/boar/boar_hunt_15.jpeg",
      },
      {
        1: "/images/hunting/boar/boar_hunt_16.jpeg",
        2: "/images/hunting/boar/boar_hunt_16.jpeg",
      },
    ],
  ]
];

export default HImages;
